import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51HXtPEB487DdbDtDsDlZhRZbPtgveEqSaVbTtwFTxS7G8dxnN36Q57J9RpPV6dI3vTMoz0QelnQXKoOTkursfYpS003BTJXQ0H"
);

const CharityClosed = () => {
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(null);

  const handleClick = () => async () => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId:
        "cs_test_a12QX5zVgQiir9wtNYfeTfiUGaPrCeEQLtm2HiXsepH2JRc9i2gMAun6vQ",
    });

    if (result.error) {
      console.log(result.error);
    }
  };
  return (
    <div>
      <div className="header" style={{ background: "#000" }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/restaurants%2Fneo-heidelberg%2Fimages%2Fgeneral%2FGhostKitchen_id_rKxbblwfUT4vaXLXobS8.svg?alt=media&token=16eafcbd-8728-488d-ad2d-4dc4d3824284"
          height="40px"
          alt="Logo"
        />
      </div>
      <div
        style={{
          backgroundImage:
            'url("https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/restaurants%2Fneo-heidelberg%2Fimages%2Fcharity%2FArtboard_id_YD65M7bTXWC5dKmhZTvU.jpg?alt=media&token=f7010600-8858-4072-b1b8-78dfa955f268")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: 300,
          marginBottom: 30,
        }}
      ></div>
      <div style={{ maxWidth: 1000, margin: "auto", padding: 20 }}>
        <h2 style={{ marginBottom: 20 }}>Liebe Charity Freunde/innen,</h2>
        <p style={{ marginBottom: 20 }}>

        vielen Dank für eure Bestellungen und Spenden.
        Wir sind noch nicht ganz am Ziel!
        Bis Mi 14.04.21 24.00 Uhr bitte noch weiter spenden.
        Es soll fünfstellig werden....

        Vielen Dank!!



        Bleiben sie gesund!

        </p>
        <div>
          <Button
            onClick={handleClick("price_1IflRtB487DdbDtDsidVC4tM")}
            color="primary"
            variant="contained"
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            25€
          </Button>
          <Button
            onClick={handleClick("price_1IflRtB487DdbDtDwQlBT1Wz")}
            color="primary"
            variant="contained"
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            50€
          </Button>
          <Button
            onClick={handleClick("price_1IflRtB487DdbDtDfZwqzNWt")}
            color="primary"
            variant="contained"
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            75€
          </Button>
          <Button
            onClick={handleClick("price_1IflRtB487DdbDtDAayxHfIs")}
            color="primary"
            variant="contained"
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            100€
          </Button>
          <Button
            onClick={handleClick("price_1IflRtB487DdbDtDUoGfWXb1")}
            color="primary"
            variant="contained"
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            200€
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CharityClosed;
