import { useContext } from "react";
import { Typography, Switch } from "@material-ui/core";
import { LanguageContext } from "../../Contexts/LanguageContext";
import { ApiContext } from "../../Contexts/ApiContext";
import { translationsHelper } from "../../CustomHooks/useTranslations";

const AllergenElement = ({
  id = "",
  iconLeft = null,
  title = "",
  value = true,
  onChange = () => {},
}) => {
  return (
    <div key={id} className="preferenceElement">
      <Typography>{title}</Typography>
      <Switch checked={value} onChange={onChange} name={id} color="primary" />
    </div>
  );
};

const Allergens = () => {
  const { texts, language } = useContext(LanguageContext);
  const { menu, menuDispatch, state } = useContext(ApiContext);

  const handleChange = ({ target: { checked, name } }) => {
    let type = checked ? "addAllergen" : "removeAllergen";
    menuDispatch({ type, payload: name });
  };

  return (
    <div>
      <div style={{ padding: 10 }}>
        <Typography style={{ fontSize: 18, fontWeight: "500" }}>
          {texts.allergens}
        </Typography>
        <Typography
          variant="caption"
          style={{ lineHeight: "14px" }}
          color="textSecondary"
        >
          {texts.allergensSubTitle}
        </Typography>
      </div>
      {state.allergens &&
        state.allergens.map((allergen) => (
          <AllergenElement
            id={allergen.id}
            title={translationsHelper(allergen.translations, language)?.title}
            value={menu.allergens.includes(allergen.id)}
            onChange={handleChange}
          />
        ))}
    </div>
  );
};

export default Allergens;
