import {
  createContext,
  useState,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import {
  blockToTime,
  dateHelper,
  timeHelper,
  timeToBlock,
} from "../helperFunctions";

const calculateMealPrice = (meal, options = [], amount) => {
  if (!meal) return 0;

  let price = meal.price || 0;

  options.forEach(({ id, value }) => {
    let option = meal?.optionsV02.find((o) => o.id === id);

    console.log({ option, value, meal });

    if (!option) return;

    if (Array.isArray(value)) {
      value.forEach((v) => {
        let opt = option.options.find((o) => v === o.id) || {};
        price += opt.price || 0;
      });
    } else {
      let opt = option.options.find((o) => value === o.id) || {};
      price += opt.price || 0;
    }
  });

  return price * amount;
};

const createTimeStamp = (waitingTime = "00:30") => {
  if (!waitingTime) {
    waitingTime = "00:30";
  }

  let [hh, mm] = waitingTime.split(":").map((t) => parseInt(t));

  let addNSec = (hh * 60 + mm) * 60000;

  let currentTime = new Date(Date.now() + addNSec)
    .toTimeString()
    .split(":")
    .slice(0, 2)
    .join(":");

  let date = dateHelper() + "T" + currentTime;

  return date;
};

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

const date = urlParams.get("date");

const modalOpen = !urlParams.get("closeModal");

export const ApiContext = createContext();

const sort = (a, b) => (a > b ? 1 : -1);

export const DEVELOPMENT =
  document.location.host.includes("localhost") && false;

export const server = axios.create({
  baseURL: DEVELOPMENT ? "http://localhost:8000" : "https://api.gastronaut.ai/",
  timeout: 10000,
});

// DEV
// export let server = axios.create({
//   baseURL: 'http://localhost:8000/v02/menues',
//   timeout: 10000
// });

let allergens = localStorage.getItem("allergens"),
  preferences = localStorage.getItem("preferences"),
  customer = localStorage.getItem("customer");

const initialState = {
  current: "regular",
  cart: [],
  allergens: allergens ? JSON.parse(allergens) : [],
  preferences: preferences ? JSON.parse(preferences) : [],
  customer: customer ? JSON.parse(customer) : {},
  deliveryPrice: 0,
  orderTime: date ? date : null,
};

function reducer(state, action) {
  let preferences = [];
  let allergens = [];
  let cart = [];
  let id = null;
  switch (action.type) {
    case "openSettings":
      return { ...state, current: "settings" };
    case "closeSettings":
      return { ...state, current: "regular" };
    case "typeChange":
      if (state.type !== action.payload) {
        return { ...state, type: action.payload };
      }
      return state;
    case "addPreference":
      preferences = [...state.preferences, action.payload];
      localStorage.setItem("preferences", JSON.stringify(preferences));
      return { ...state, preferences };
    case "removePreference":
      preferences = state.preferences.filter((p) => p !== action.payload);
      localStorage.setItem("preferences", JSON.stringify(preferences));
      return { ...state, preferences };
    case "addAllergen":
      allergens = [...state.allergens, action.payload];
      localStorage.setItem("allergens", JSON.stringify(allergens));
      return { ...state, allergens };
    case "removeAllergen":
      allergens = state.allergens.filter((p) => p !== action.payload);
      localStorage.setItem("allergens", JSON.stringify(allergens));
      return { ...state, allergens };
    case "addToCart":
      id =
        typeof action.payload === "object" ? action.payload.id : action.payload;

      cart = Array.from(state.cart);

      let index = cart.findIndex(
        (i) =>
          i.id === id &&
          (!action.options ||
            JSON.stringify(i.options || []) === action.options)
      );

      let { amount = 1 } = action;

      if (index !== -1 && typeof action.payload !== "object") {
        let newAmount = cart[index].amount + amount;

        if (newAmount) {
          cart[index] = { ...cart[index], amount: newAmount };
        } else {
          cart.splice(index, 1);
        }
      } else {
        cart.push(
          typeof action.payload === "object"
            ? action.payload
            : { id: action.payload, amount: 1 }
        );
      }

      localStorage.setItem("cart", JSON.stringify(cart));
      return { ...state, cart };
    case "removeFromCart":
      id = action.payload;
      cart = state.cart.filter(
        (p) =>
          !(p.id === id && JSON.stringify(p.options || []) === action.options)
      );
      localStorage.setItem("cart", JSON.stringify(cart));
      return { ...state, cart };
    case "deliveryPrice":
      return {
        ...state,
        deliveryPrice: action?.payload?.price || 0,
        minOrderValue: action?.payload?.minOrderValue || 0,
      };
    case "orderMethod":
      return { ...state, orderMethod: action.payload };
    case "paymentMethod":
      return { ...state, paymentMethod: action.payload };
    case "voucher":
      return { ...state, voucher: action.payload };
    case "removeVoucher":
      return { ...state, voucher: null };
    case "coupon":
      return { ...state, coupon: action.payload };
    case "removeCoupon":
      return { ...state, coupon: null };
    case "orderTime":
      if (action.adClick) {
        return {
          ...state,
          orderTime: action.payload.date,
          oldOrderTime: state.orderTime,
        };
      } else {
        return {
          ...state,
          orderTime: action.payload.date,
          oldOrderTime: null,
          deliveryMethods: action.payload?.shift?.deliveryMethods,
        };
      }
    case "goBack":
      return { ...state, orderTime: state.oldOrderTime, oldOrderTime: null };
    case "tipAdded":
      return { ...state, tip: action.payload };
    case "tipRemoved":
      return { ...state, tip: null };
    case "addCostumerData":
      localStorage.setItem(
        "savedMenu",
        JSON.stringify({ ...state, customer: action.payload })
      );
      return { ...state, customer: action.payload };
    case "reloadSavedMenu":
      let savedMenu = localStorage.getItem("savedMenu");

      if (savedMenu) {
        return JSON.parse(savedMenu);
      } else {
        return state;
      }
    default:
      throw new Error();
  }
}

const matchSpecialMenu = (
  { id, active },
  { mealsAvailable = [], onlySelectedMeals = false }
) => {
  return (
    (!onlySelectedMeals && active) ||
    mealsAvailable.find((m) => m === id || m.id === id)
  );
};

const matchPreferences = (
  { vegan, alcoholic, vegetarian },
  preferences = []
) => {
  if (!preferences.length) return true;

  if (preferences.includes("noAlc") && alcoholic) return false;

  if (preferences.includes("vegan") && !vegan) return false;

  if (preferences.includes("veggie") && !vegetarian) return false;

  return true;
};

let interval;

const calculateTip = (subTotal, tip) => {
  if (!tip) return 0;

  if (tip.type === "percentage") {
    return Math.round(subTotal * tip.amount) / 100;
  } else {
    return tip.amount;
  }
};

const calculateVoucher = (voucher) => {
  if (!voucher || !voucher.valid) return 0;

  return voucher.amount;
};

const calculateCoupon = (subTotal, coupon) => {
  console.log({ subTotal, coupon });
  if (!coupon) return 0;

  if (coupon.type === "percentage") {
    return Math.round(subTotal * coupon.amount) / 100;
  }

  return coupon.amount;
};

const calculateServiceFee = (paymentMethod, total) => {
  if (!paymentMethod) return 0;

  let fee = 0.9;

  if (paymentMethod === "card") {
    fee += Math.round(total * 15) / 1000;
  }

  return fee;
};

const inFuture = (dates = []) => {
  let today = dateHelper();

  return dates.some((x) => x >= today);
};

const initialSpecialMenu = { mealsAvailable: [], onlySelectedMeals: false };

const ApiContextProvider = ({
  children,
  setColorPalette,
  setPixel,
  trackEvent,
  pixel,
  setmeta,
}) => {
  const [userInfo, setuserInfo] = useState(
    localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : { errors: {}, loading: false }
  );
  const [modal, setmodal] = useState(false);
  const [restaurantId, setrestaurantId] = useState(null);
  const [currentCategory, setcurrentCategory] = useState(null);
  const [optionModal, setOptionModal] = useState(null);
  const [search, setsearch] = useState(null);
  const [state, setstate] = useState({
    loaded: false,
  });
  const [allergenModal, setallergenModal] = useState({
    open: false,
    ref: null,
    allergens: [],
  });
  const [menu, menuDispatch] = useReducer(reducer, initialState);
  const [cartOpen, setcartOpen] = useState(false);

  useEffect(() => {
    setmodal(modalOpen);
    if (!restaurantId) return;
    init(restaurantId);
    if (interval) clearInterval(interval);
    interval = setInterval(checkAvailability, 60000);
  }, [restaurantId]);

  const [specialMenu, setspecialMenu] = useState(initialSpecialMenu);

  useEffect(() => {
    if (menu.orderTime && restaurantId) getSpecialMeals(menu.orderTime);
  }, [menu.orderTime, restaurantId]);

  const [calendar, setcalendar] = useState(null);
  const [calendarLoading, setcalendarLoading] = useState(false);

  const loadMonth = useCallback(async (monthId) => {
    if (calendarLoading || !restaurantId) return;
    setcalendarLoading(true);

    try {
      const today = dateHelper();

      const { data } = await server.get(
        `v03/shifts/${restaurantId}/month/${monthId.slice(0,7)}/takeAway`
      );

      console.log(data);

      const obj = data.dates.reduce((acc, cV) => {
        return {
          ...acc,
          [cV.date]: cV.shifts,
        };
      }, {});

      setcalendar(old => ({ ...(old ?? {}), ...obj }));
    } catch (error) {
      console.error(error);
    }

    setcalendarLoading(false);
  }, [calendarLoading, restaurantId]);

  const loadThisMonth = useCallback(async () => {
    if (calendarLoading || !restaurantId) return;
    setcalendarLoading(true);

    try {
      const today = dateHelper();

      await loadMonth(today);

      let date = new Date();

      date.setMonth(date.getMonth() + 1);

      return loadMonth(date.toISOString())
    } catch (error) {
      console.error(error);
    }

    setcalendarLoading(false);
  }, [calendarLoading, restaurantId]);

  useEffect(() => {
    loadThisMonth();
  }, [restaurantId]);

  const getSpecialMeals = async (orderTime = "now") => {
    try {
      if (orderTime === "now") {
        orderTime = `${new Date().toISOString().split("T")[0]}T${new Date()
          .toLocaleTimeString("de-DE")
          .slice(0, 5)}`;
      }

      let [date, time] = orderTime.split("T");

      if (!time) return;

      const { data } = await server.get(
        `v03/takeAway/${restaurantId}/takeAwayAvailability/${date}/${time}${
          state.shiftVersion.toLocaleLowerCase() === "v02" ? "/v02" : ""
        }`
      );

      //api.gastronaut.ai
      console.log("data from api", data);

      setstate((st) => ({ ...st, ...data, isOpen: st.isOpen }));
      setspecialMenu((sM) => ({
        ...sM,
        deliveryMethods: data?.deliveryMethods,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const init = async (restaurantId) => {
    try {
      if (restaurantId === "error") return;

      const { data } = await server.get(
        `v03/takeAway/${restaurantId}` // `https://api.gastronaut.ai/v03/takeAway/${restaurantId}`
      );

      const { data: unavailable } = await server.get(
        `v02/menues/availability/${restaurantId}`
      );

      if (data.categories.length) {
        // setcurrentCategory(data.categories[0].id);
      }

      if (data?.restaurant?.colorPalette?.primaryColor) {
        setColorPalette(data.restaurant.colorPalette);
      }

      if (data?.restaurant?.fbPixel) {
        setPixel(data.restaurant.fbPixel);
      }

      if (data?.restaurant?.metaData) {
        setmeta(data.restaurant.metaData);
      }

      let serviceFee = data?.meals?.find(x => x.shortName === "Service-Gebühr");

      if(!!serviceFee) {
        menuDispatch({
          type: "addToCart",
          payload: serviceFee.id,
          amount: 1,
          options: "",
       });
      }

      console.log(data);

      setstate({
        ...data,
        unavailable,
        loaded: true,
        shiftVersion: 'v02',
      });
    } catch (error) {
      console.error(error.response);
      setstate({ loaded: true, error: error.response });
      //window.location.replace('/error');
      return;
    }
  };

  const checkAvailability = async () => {
    try {
      if (!state.date) return;
      const { data: unavailable } = await server.get(
        `v02/menues/availability/${restaurantId}/${state.date}`
      );

      if (JSON.stringify(unavailable) === JSON.stringify(state.unavailable))
        return;

      setstate((st) => ({ ...st, unavailable }));
    } catch (error) {
      setstate({ loaded: true, error: error.response });
      return;
    }
  };

  const filteredMeals = useMemo(() => {
    if (!state.meals) return [];

    return state.meals.filter(
      (m) =>
        matchSpecialMenu(m, specialMenu) &&
        matchPreferences(m, menu.preferences) && 
        m.shortName !== "Service-Gebühr"
    );
  }, [state, menu.preferences, specialMenu]);

  const filteredCategories = useMemo(() => {
    if (!state.categories) return [];

    return state.categories
      .map((cat) => ({
        ...cat,
        meals: filteredMeals.filter((m) => m.category == cat.id),
      }))
      .filter((cat) => cat.active && !!cat.meals.length)
      .sort((a, b) => parseInt(a.position) - parseInt(b.position));
  }, [state, menu.type, menu.preferences, filteredMeals]);

  console.log({
    filteredMeals,
    filteredCategories,
    meals: state.meals,
    specialMenu,
    menu,
  });

  const openAllergenModal =
    (allergens = []) =>
    ({ target }) => {
      setallergenModal({ open: true, ref: target, allergens });
    };

  // const currentPrice = useMemo(() => {
  //   if (!state.meals || !state.meals.length) return 0;

  //   return menu.cart.reduce((acc, cV) => {
  //     if (typeof cV !== 'object') {
  //       cV = { amount: 1, id: cV };
  //     }

  //     let meal = state.meals.find(m => m.id === cV.id);

  //     if (!meal) return acc;

  //     return acc + meal.price * cV.amount;
  //   }, 0);
  // }, [menu.cart, state.meals]);

  const calculateDeliveryCosts = async (zipCode) => {
    try {
      const { data } = await server.get(
        `v02/menues/takeAway/${restaurantId}/calculateDeliveryCosts/${zipCode}`
      );

      if (!data.error) {
        menuDispatch({ type: "deliveryPrice", payload: data });
      }

      return data;
    } catch (error) {
      return error.response.data.message;
    }
  };

  const handleVoucher = useCallback(
    async (voucherId) => {
      try {
        const { data } = await server.get(
          `v02/menues/takeAway/${restaurantId}/getVoucher/${voucherId}`
        );

        menuDispatch({ type: "voucher", payload: data });
        trackEvent("VoucherAdded", { payload: data });

        return data;
      } catch (error) {
        console.error(error.response);
        return { error: error.response.data.message };
      }
    },
    [menuDispatch, restaurantId]
  );

  const handleCoupon = useCallback(
    async (couponId) => {
      try {
        const { data } = await server.get(
          `v02/menues/takeAway/${restaurantId}/getCoupon/${couponId}`
        );

        let payload = {
          ...data,
          id: couponId,
        };

        menuDispatch({ type: "coupon", payload });
        trackEvent("CouponAdded", { payload });

        return data;
      } catch (error) {
        console.error(error.response);
        return { error: error.response.data.message };
      }
    },
    [menuDispatch, restaurantId]
  );

  const calculations = useMemo(() => {
    const subTotal = menu.cart.reduce((acc, cV) => {
      if (typeof cV !== "object") {
        cV = { amount: 1, id: cV };
      }

      let meal = state?.meals?.find((m) => m.id === cV.id);

      if (!meal) return acc;

      let mealPrice = calculateMealPrice(meal, cV.options, cV.amount);

      return acc + mealPrice;
    }, 0);

    const deliveryFee =
      menu.orderMethod === "delivery" ? menu.deliveryPrice : 0;

    const voucher = calculateVoucher(menu.voucher);

    const coupon = calculateCoupon(
      Math.max(subTotal - voucher, 0),
      menu.coupon
    );

    const tip = calculateTip(subTotal, menu.tip);

    // const serviceFee = calculateServiceFee(
    //   menu.paymentMethod,
    //   Math.max(subTotal - voucher - coupon, 0) + tip + deliveryFee
    // );

    const serviceFee = 0;

    const total =
      Math.max(subTotal - voucher - coupon, 0) + tip + serviceFee + deliveryFee;

    return { total, subTotal, deliveryFee, tip, serviceFee, coupon, voucher };
  }, [menu, state.meals]);

  console.log({ state });

  const createPayment = useCallback(
    async (data = {}) => {
      try {
        let deliveryTime = "";

        if (!data.orderTime.includes("-")) {
          let waitingTime =
            menu?.restaurant?.currentWaitingTimes[data.deliveryMethod] ||
            "00:30";
          data.orderTime = "now";
          deliveryTime = createTimeStamp(waitingTime);
        } else {
          deliveryTime = data.orderTime;
        }

        data.deliveryTime = deliveryTime;
        data.version = "v02";
        data.paymentProvider = state.paymentProvider;

        console.log({ state });

        const { data: result } = await server.post(
          `v03/takeAway/${restaurantId}/createPayment`,
          data
        );

        let { name, email, phone } = data;

        let payload = {
          name,
          email,
          phone,
        };

        menuDispatch({ type: "addCostumerData", payload });

        trackEvent("Purchase", { currency: "EUR", value: calculations.total });

        setstate((st) => ({
          ...st,
          custom: { orderMethod: data.orderMethod, deliveryTime },
        }));

        return { result };
      } catch (error) {
        if (error.response) {
          return { error: error.response.data.message };
        } else {
          return { error: error.message };
        }
      }
    },
    [restaurantId, pixel, state]
  );

  const deliveryMethods = useMemo(() => {
    console.log(specialMenu, state.restaurant);

    if (specialMenu.deliveryMethods) {
      return specialMenu.deliveryMethods;
    } else {
      return state.deliveryMethods ?? ["pickup"];
    }
  }, [specialMenu, state.restaurant, calendar]);

  const ads = useMemo(() => {
    if (!calendar && (!state.specialDays || !state.specialDays.length))
      return [];

    let ads = (state?.specialDays ?? [])
      .map(({ advert = null, id, dates = [] }) =>
        advert ? { ...advert, id, dates } : null
      )
      .filter((ad) => ad && ad.active && inFuture(ad.dates));

    Object.entries(calendar ?? {}).forEach(([date, shifts]) => {
      if (date < dateHelper()) return;

      let adsOnThisDay = shifts
        .filter((s) => !s.closed && !s.disabled && s.active)
        .map(({ advert = null, id, dates = [date] }) =>
          advert
            ? { ...advert, id, dates: dates.filter((d) => d >= dateHelper()) }
            : null
        )
        .filter((x) => !!x);

      adsOnThisDay.forEach((ad) => {
        let index = ads.find((a) => a.id === ad.id);

        if (index > -1) {
          ads[index].dates = [...(ads?.[index]?.dates ?? []), date].sort(
            (a, b) => a?.localCompare(b) ?? 0
          );
        } else {
          ads.push(ad);
        }
      });
    });

    return ads
      .filter((a) => a.dates.length && a.active)
      .sort((a, b) => sort(a.dates[0], b.dates[0]));
  }, [state.specialDays, calendar]);

  const handleAdClick = (id) => () => {
    console.log({ id, sD: state.specialDays });

    const date = ads.find((a) => a.id === id)?.dates?.[0];

    if (!date) return;

    let start;

    if (state?.specialDays?.find((sD) => sD.id === id)) {
      let specialDay = state?.specialDays?.find((sD) => sD.id === id);

      start = specialDay.start;
    } else {
      let shift = calendar?.[date]?.find((s) => s.id === id);

      if (shift) {
        start = blockToTime(shift.start);
      }
    }

    if (start && date) {
      let payload = `${date}T${start}`;

      trackEvent("OrderTime", { payload });
      menuDispatch({
        type: "orderTime",
        payload: { date: payload },
        adClick: true,
      });

      menuDispatch({ type: "orderMethod", payload: "" });
    }
  };

  const [imageModal, setImageModal] = useState(null);

  const openImageModal = (img) => {
    setImageModal(img);
  };

  const closeImageModal = () => setImageModal(null);

  const cartItems = useMemo(() => {
    if (!menu.cart || !menu.cart.length) return 0;

    return menu.cart.reduce((acc, cV) => acc + cV.amount, 0);
  }, [menu.cart]);

  const goBack = () => {
    menuDispatch({ type: "goBack" });
    setspecialMenu(initialSpecialMenu);
  };

  const minOrderValue = useMemo(() => {
    if (menu?.orderMethod === "pickup") {
      return state?.restaurant?.minOrderValue?.pickup || 0;
    } else if (menu?.minOrderValue !== undefined) {
      return menu?.minOrderValue || 0;
    } else {
      return state?.restaurant?.minOrderValue[menu.orderMethod] || 0;
    }
  }, [state, menu]);

  return (
    <ApiContext.Provider
      value={{
        state,
        setstate,
        menu,
        menuDispatch,
        restaurantId,
        setrestaurantId,
        filteredCategories,
        openAllergenModal,
        allergenModal,
        setallergenModal,
        filteredMeals,
        calculateDeliveryCosts,
        currentCategory,
        setcurrentCategory,
        search,
        setsearch,
        modal,
        setmodal,
        handleVoucher,
        createPayment,
        handleCoupon,
        calculations,
        userInfo,
        setuserInfo,
        trackEvent,
        deliveryMethods,
        ads,
        handleAdClick,
        imageModal,
        openImageModal,
        closeImageModal,
        cartOpen,
        setcartOpen,
        cartItems,
        goBack,
        optionModal,
        setOptionModal,
        minOrderValue,
        calendar,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;
