import { Button, Typography } from "@material-ui/core";
import React, { useState, useMemo } from "react";
import Icon from "./Icon";
import AddToCart from "./AddToCart";
import Allergens from "./Allergens";
import { ReactComponent as Vegan } from "./Icons/Vegan.svg";
import { ReactComponent as Veggie } from "./Icons/Veggie.svg";
import { stringifyPrice } from "../../../helperFunctions";
import { AddRounded, RemoveRounded } from "@material-ui/icons";
import useTranslations from "../../../CustomHooks/useTranslations";

const texts = {
  de: {
    vegan: "Vegan",
    veggie: "Vegetarisch",
    attention:
      "Dieses Gericht könnte bei Ihnen allergische Reaktionen auslösen",
    unavailable: "Leider aktuell nicht verfügbar",
    open: "Schließe Empfehlungen",
    close: "Zeige Empfehlungen",
  },
  en: {
    vegan: "Vegan",
    veggie: "Vegetarian",
    attention: "You may be allergic to this dish",
    unavailable: "Sadly currently not available",
    open: "Close Recomendations",
    close: "Show Recomendations",
  },
};

const FoodPairing = ({ meal = {}, language = "de" }) => {
  const {
    title = "",
    tags = "",
    description = "",
  } = useTranslations(meal.translations);

  return (
    <div key={meal.id} style={{ padding: 10, paddingTop: 20 }}>
      <div className="mealHeader">
        <Typography variant="h3">{title}</Typography>
        <div>
          <Allergens allergens={meal.allergens} />
          {meal.vegan && (
            <Icon icon={<Vegan />} tooltip={texts[language].vegan} />
          )}
          {meal.vegetarian && (
            <Icon icon={<Veggie />} tooltip={texts[language].veggie} />
          )}
        </div>
        <Typography variant="h3">{stringifyPrice(meal.price, "€")}</Typography>
      </div>
      <Typography
        className="mealDescription"
        dangerouslySetInnerHTML={{
          __html: description?.replace(
            /(<p><i><\/i><\/p>)|(<p><\/p>)/gm,
            "<br/>"
          ),
        }}
      />
      <AddToCart id={meal.id} language={language} />
    </div>
  );
};

const FoodPairings = ({ foodPairings = [], language = "de" }) => {
  const [open, setopen] = useState(false);

  const buttonLabel = open ? texts[language].open : texts[language].close;

  return (
    <div className="food-pairing-container">
      {open &&
        foodPairings.map((meal) => (
          <FoodPairing meal={meal} language={language} />
        ))}
      <div
        className="food-pairing-toggle"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          onClick={() => setopen((cV) => !cV)}
          startIcon={open ? <RemoveRounded /> : <AddRounded />}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default FoodPairings;
