import { createContext, useState, useMemo } from "react";

const translations = {
  de: {
    food: "Essen",
    drinks: "Getränke",
    settingsHeader: "Individualiserung",
    vegan: "Vegane Ernährung",
    veggie: "Vegetarische Ernähung",
    noAlc: "Kein Alkohol",
    allergens: "Allergien",
    allergensSubTitle:
      "Wir markieren problematische Gerichte mit einer Warnung",
    categories: "Kategorien",
  },
  en: {
    food: "Food",
    drinks: "Drinks",
    settingsHeader: "Individualisation",
    vegan: "Vegan Diet",
    veggie: "Vegetarian Diet",
    noAlc: "Kein Alkohol",
    allergens: "Allergens",
    allergensSubTitle: "",
    categories: "Categories",
  },
};

const savedLanguage = localStorage.getItem("language");

export const LanguageContext = createContext();

const LanguageContextProvider = ({ production, children }) => {
  const [language, setlanguage] = useState(
    savedLanguage
      ? savedLanguage
      : navigator.language.includes("de")
      ? "de"
      : "en"
  );

  const texts = useMemo(() => translations[language], [language]);

  const handleLanguageChange = (newLang) => {
    localStorage.setItem("language", newLang);
    setlanguage(newLang);
  };

  return (
    <LanguageContext.Provider value={{ handleLanguageChange, language, texts }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
