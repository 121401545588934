import Tooltip from '@material-ui/core/Tooltip';

const Icon = ({ icon = null, tooltip = '' }) => {
  return (
    <Tooltip disableFocusListener title={tooltip}>
      {icon}
    </Tooltip>
  );
};

export default Icon;
