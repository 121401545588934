import { Typography } from "@material-ui/core";
const CategoryImage = ({
  id,
  title = "",
  description = "",
  img = "",
  styleOptions: { categoryHeight = "normal", categoryStyle = "1" },
}) => {
  if (categoryStyle === "1") {
    return (
      <div
        className={"category-item-icon category-item-height-" + categoryHeight}
        key={id}
      >
        <h4>{title}</h4>
        <div
          style={{
            backgroundImage: img ? `url("${img}")` : null,
          }}
          alt={title}
        ></div>
      </div>
    );
  } else if (categoryStyle === "2") {
    return (
      <div
        className={"subCategoryItem category-item-height-" + categoryHeight}
        key={id}
        style={{
          backgroundImage: img
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${img}")`
            : "#000",
        }}
      >
        <Typography>{title}</Typography>
      </div>
    );
  } else if (categoryStyle === "3") {
    return (
      <div className="categoryItem3" key={id}>
        {!!img && (
          <div
            className="categoryItem3-img"
            style={{
              backgroundImage: `url("${img}")`,
            }}
            alt={title}
          ></div>
        )}
        <div className="categoryItem3-text">
          <Typography variant="h3">{title}</Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: description?.replace(
                /(<p><i><\/i><\/p>)|(<p><\/p>)/gm,
                "<br/>"
              ),
            }}
          />
        </div>
      </div>
    );
  }
};
export default CategoryImage;
