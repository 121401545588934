import { useContext } from "react";
import { ApiContext } from "../../Contexts/ApiContext";

const Header = () => {
  const {
    state: { restaurant = {} },
  } = useContext(ApiContext);

  if (
    window.location.pathname.includes("error") ||
    window.location.pathname.includes("charity")
  )
    return <></>;

  if (restaurant.headerBackground) {
    return (
      <div
        className="header backgroundImage"
        style={{
          backgroundImage: `url(${restaurant.headerBackground})`,
        }}
      ></div>
    );
  }

  return (
    <div className="header" style={{ background: restaurant.headerColor }}>
      <img src={restaurant.logo} height="40px" alt="Logo" />
    </div>
  );
};

export default Header;
