import { useContext, useMemo } from 'react';
import { ApiContext } from '../../Contexts/ApiContext';
import { LanguageContext } from '../../Contexts/LanguageContext';
import { Popover, Typography } from '@material-ui/core';

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right'
};

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right'
};

const AllergenModal = () => {
  const { allergenModal, setallergenModal, state } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);

  console.log(allergenModal);

  const handleClose = () =>
    setallergenModal({ open: false, ref: null, allergens: [] });

  const allergens = useMemo(() => {
    if (!allergenModal.allergens || !state.allergens) return [];

    return state.allergens
      .filter(a => allergenModal.allergens.includes(a.id))
      .map(a => ({ id: a.id, ...a.translations[language] }));
  }, [state.allergens, allergenModal, language]);

  return (
    <Popover
      id="allergenModal"
      open={allergenModal.open}
      anchorEl={allergenModal.ref}
      onClose={handleClose}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
      PaperProps={{ className: 'allergenModal' }}
    >
      <div>
        {allergens.map(a => (
          <div key={a.id} className="allergen-modal-item">
            <Typography>
              <strong>{a.id}</strong>
            </Typography>
            <div>
              <Typography>
                <strong>{a.title}</strong>
              </Typography>
              <Typography>{a.description}</Typography>
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );
};

export default AllergenModal;
