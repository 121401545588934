import { useContext, useMemo } from "react";
import { ApiContext } from "../../../Contexts/ApiContext";
import { stringifyPrice } from "../../../helperFunctions";
import { DeleteOutlineRounded, Add, Remove } from "@material-ui/icons";
import { ButtonGroup, Button, IconButton } from "@material-ui/core";
import useTranslations, {
  translationsHelper,
} from "../../../CustomHooks/useTranslations";

const CartItem = ({
  data: { id, amount, options = [] },
  language = "de",
  editable = true,
}) => {
  const { state, menuDispatch } = useContext(ApiContext);

  const meal = useMemo(
    () => (state.meals ? state.meals.find((m) => m.id === id) : {}),
    [id, state]
  );

  const { title = "" } = useTranslations(meal.translations);

  const isServiceFee = meal?.shortName === "Service-Gebühr";

  const calculatedPrice = useMemo(() => {
    if (!meal) return 0;

    let price = meal.takeAwayPrice ?? meal.price ?? 0;

    options.forEach(({ id, value }) => {
      let option = meal?.optionsV02.find((o) => o.id === id);

      if (!option) return;

      if (Array.isArray(value)) {
        value.forEach((v) => {
          let opt = option.options.find((o) => v === o.id) || {};
          price += opt.price || 0;
        });
      } else {
        let opt = option.options.find((o) => value === o.id) || {};
        price += opt.price || 0;
      }
    });

    return price * amount;
  }, [meal, options, amount]);

  const stringifiedOptions = useMemo(() => {
    if (!meal) return [];

    let arr = [];

    options.forEach(({ id, value }) => {
      let option = meal?.optionsV02.find((o) => o.id === id);

      let { title = "" } = translationsHelper(option.translations, language);

      let str = title ? `${title}: ` : "";

      if (!option) return;

      if (Array.isArray(value)) {
        let strArr = [];

        value.forEach((v) => {
          let opt = option.options.find((o) => v === o.id) || {};

          let title = translationsHelper(opt.translations, language);
          if (title) {
            strArr.push(title);
          }
        });

        arr.push(str + strArr.join(", "));
      } else {
        let opt = option.options.find((o) => value === o.id) || {};
        let title = translationsHelper(opt.translations, language);
        if (title) {
          arr.push(str + title);
        }
      }
    });

    return arr;
  }, [meal, options, amount, language]);

  const handleDelete = () =>
    menuDispatch({
      type: "removeFromCart",
      payload: id,
      options: JSON.stringify(options),
    });

  const handleClick = (amount) => () =>
    menuDispatch({
      type: "addToCart",
      payload: id,
      amount,
      options: JSON.stringify(options),
    });

  return (
    <div className="cart-item">
      <div style={{ alignItems: "baseline" }}>
        <span className="cart-item-amount">{amount}x</span>
        <div style={{ flexGrow: 10 }}>
          <span className="cart-item-title">{title}</span>
          {stringifiedOptions.map((str) => (
            <small style={{ opacity: 0.8, display: "block" }} key={str}>
              {str}
            </small>
          ))}
        </div>
        <span className="cart-item-price">
          {stringifyPrice(calculatedPrice, "€")}
        </span>
      </div>
      {!isServiceFee && <div
        className="cart-item-actions"
        style={{ display: !editable ? "none" : null }}
      >
        <IconButton onClick={handleDelete} size="small">
          <DeleteOutlineRounded />
        </IconButton>

        <ButtonGroup size="small" color="textPrimary">
          <Button style={{ minWidth: 32 }} onClick={handleClick(-1)}>
            <Remove style={{ width: 12, height: 12 }} />
          </Button>
          <Button style={{ minWidth: 32 }} onClick={handleClick(1)}>
            <Add style={{ width: 12, height: 12 }} />
          </Button>
        </ButtonGroup>
      </div>}
    </div>
  );
};

export default CartItem;
