import { useContext, useEffect, useState, useMemo } from "react";
import ShoppingCart from "../Desktop/ShoppingCart";
import {
  IconButton,
  Divider,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { ApiContext } from "../../../Contexts/ApiContext";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { ArrowBackRounded } from "@material-ui/icons";
import PaymentMethods from "./PaymentMethods";
import Voucher from "./Voucher";
import Coupon from "./Coupon";
import Tip from "./Tip";
import Alert from "@material-ui/lab/Alert";
import TermsAndConditions from "./TermsAndConditions";
import { stringifyPrice } from "../../../helperFunctions";
import OrderMethodAndTime from "./OrderMethodAndTime";

const translations = {
  de: {
    orderNow: "Jetzt kostenpflichtig bestellen",
    terms:
      "Hiermit bestätige ich die geltenden Allgemeinen Geschäftsbedingungen",
    call: "Fahrer soll anrufen wenn vor der Tür",
    title: "Bestell Informationen",
    addressTitle: "Lieferadresse",
    streetLabel: "Straße und Hausnummer",
    zipCode: "Postleitzahl",
    adressZusatz: "Adress Zusatz",
    cityLabel: "Stadt",
    guestTitle: "Kontakt Person",
    nameLabel: "Vor und Nachname",
    phoneLabel: "Telefon-Nummer",
    emailLabel: "Email",
    companyLabel: "Firmenname",
    commentLabel: "Kommentar",
    personLabel: "Personen-Anzahl",
    paymentMethod: "Bezahloption wählen",
    preOrder: "Vorbestellung",
    orderMethodTime: "Liefermethode und Lieferzeit",
    mollieError: 'Es ist ein Fehler aufgetreten bei dem versuch einen Zahlungslink zu erstellen',
    mollieRedirectError: 'Bitte klicke den Link um die Zahlung abzuschließen'
  },
  en: {
    orderNow: "Order now",
    terms: "I herby confirm the underlying Terms and Conditions",
    call: "Driver should call when in front of the door",
    title: "Order Informations",
    addressTitle: "Deliveryadress",
    streetLabel: "Street and Housenumber",
    zipCode: "ZIP Code",
    adressZusatz: "Additional Address",
    cityLabel: "City",
    guestTitle: "Contact Person",
    nameLabel: "First and Second Name",
    phoneLabel: "Phone-Number",
    emailLabel: "Email",
    companyLabel: "Company",
    commentLabel: "Comment",
    personLabel: "Persons",
    paymentMethod: "Choose Payment Method",
    orderMethodTime: "OrderMethod and Time",
    mollieError: 'An error occurred while trying to create a payment link',
    mollieRedirectError: 'Please click the link to complete the payment'
  },
};

const UserInformation = ({ match, history }) => {
  const [tAndC, setTAndC] = useState(false);
  const { restaurantId } = match.params;
  const {
    setrestaurantId,
    state,
    menuDispatch,
    menu,
    calculations: { subTotal, total },
    createPayment,
    userInfo,
    setuserInfo,
    minOrderValue = 0,
  } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);

  const texts = translations[language];

  const goBack = () => history.push(`/${restaurantId}`);

  useEffect(() => {
    if (!state.loaded) {
      goBack();
    }
    setrestaurantId(restaurantId);
  }, [restaurantId]);

  const mobile = window.innerWidth < 760;

  const handleChange = ({ target: { name, value, checked } }) => {
    setuserInfo((pV) => ({
      ...pV,
      [name]: name === "call" || name === "terms" ? checked : value,
      errors: { ...pV.errors, [name]: null },
    }));
  };

  const handleBlur = ({ target: { name, value } }) => {
    if (!value) {
      setuserInfo((pV) => ({
        ...pV,
        errors: {
          ...pV.errors,
          [name]: language === "de" ? "Pflichtfeld" : "Can't be Empty",
        },
      }));
    }
  };

  const handlePaymentChange = (paymentMethod) => {
    setuserInfo((uI) => ({
      ...uI,
      paymentMethod,
      errors: { ...uI.errors, paymentMethod: null },
    }));
    menuDispatch({ type: "paymentMethod", payload: paymentMethod });
  };

  const handleSubmit = async () => {
    if (userInfo.loading) {
      return;
    }
    let minOrderReached = subTotal > minOrderValue;

    let requiredFields = ["name", "email", "phone", "paymentMethod", "terms"];

    if (menu.orderMethod === "delivery") {
      requiredFields.push("street", "zipCode", "city");
    }

    let msg = language === "de" ? "Pflichtfeld" : "Mandatory Field";

    let errors = {};

    requiredFields.forEach((key) => {
      if (!userInfo[key]) {
        errors[key] = msg;
      }

      setuserInfo({ ...userInfo, errors });

      return;
    });

    if (Object.keys(errors).length || !minOrderReached) {
      if (!minOrderReached) {
        errors.overall =
          language === "de"
            ? "Mindest Lieferbetrag nicht erreicht"
            : "Minimum Order value not reached";
      } else {
        errors.overall =
          language === "de"
            ? "Mindestens 1 Plichtangabe fehlt"
            : "Minimum 1 Field is missing";
      }

      setuserInfo({ ...userInfo, errors });

      return;
    }

    localStorage.setItem(
      "userInfo",
      JSON.stringify({ ...userInfo, errors: {} })
    );

    setuserInfo({ ...userInfo, errors, loading: true });

    let data = {
      ...userInfo,
      ...menu,
    };

    const validateOrderTime = (orderTime) => {
      return !orderTime.match(
        /(20\d{2}\-[0,1]\d\-[0-3]\d[T][0-2]\d\:\d{2})|(now)/gm
      );
    };

    if (validateOrderTime(data.orderTime)) {
      data.orderTime = "now";
    }

    delete data.errors;
    delete data.terms;
    delete data.loading;

    const { result, error } = await createPayment(data);

    if (error) {
      setuserInfo({ ...userInfo, errors: { overall: texts[error] } });
    } else if (result.success) {
      history.push("success");
    } else if(result.paymentProvider === "mollie") {
      if(result.paymentUrl) {
        window.location.href = result.paymentUrl;

        setTimeout(() => {
          setuserInfo({ ...userInfo, loading: false, error: { overall: texts.mollieError, link: result.paymentUrl } });
        }, 2000);

      } else {
        setuserInfo({ ...userInfo, errors: { overall: texts.mollieError } });
      }
    } else if (result.payableItemId) {
      history.push(result.payableItemId + "/" + data.paymentMethod);
    } 

    return;
  };

  const paymentMethods = useMemo(
    () => {
      if (!state.restaurant) return [];

      return state.restaurant.paymentMethods[menu.orderMethod];
    },
    state.restaurant,
    menu.orderMethod
  );

  return (
    <>
      <section className="user-information-page">
        <div className="sub-header">
          <IconButton size="small" onClick={goBack} style={{ marginRight: 10 }}>
            <ArrowBackRounded />
          </IconButton>
          <span>{texts.title}</span>
        </div>
        <main>
          <div>
            <Grid container spacing={2}>
              {menu.orderMethod === "delivery" && (
                <>
                  <Grid item xs={12}>
                    <h2>{texts.addressTitle}</h2>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="street"
                      required
                      value={userInfo.street}
                      label={texts.streetLabel}
                      fullWidth
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={userInfo.errors.street}
                      helperText={userInfo.errors.street}
                    />
                    <TextField
                      name="adressZusatz"
                      value={userInfo.adressZusatz}
                      label={texts.adressZusatz}
                      fullWidth
                      margin="normal"
                      onChange={handleChange}
                      error={userInfo.errors.adressZusatz}
                      helperText={userInfo.errors.adressZusatz}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="zipCode"
                      required
                      value={userInfo.zipCode}
                      label={texts.zipCode}
                      fullWidth
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={userInfo.errors.zipCode}
                      helperText={userInfo.errors.zipCode}
                    />
                    <TextField
                      name="city"
                      required
                      value={userInfo.city}
                      label={texts.cityLabel}
                      fullWidth
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={userInfo.errors.city}
                      helperText={userInfo.errors.city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <h2>{texts.guestTitle}</h2>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  required
                  value={userInfo.name}
                  label={texts.nameLabel}
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={userInfo.errors.name}
                  helperText={userInfo.errors.name}
                />
                <TextField
                  name="phone"
                  required
                  value={userInfo.phone}
                  label={texts.phoneLabel}
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={userInfo.errors.phone}
                  helperText={userInfo.errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  required
                  value={userInfo.email}
                  label={texts.emailLabel}
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={userInfo.errors.email}
                  helperText={userInfo.errors.email}
                />
                <TextField
                  name="company"
                  value={userInfo.company}
                  label={texts.companyLabel}
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  error={userInfo.errors.company}
                  helperText={userInfo.errors.company}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="comment"
                  value={userInfo.comment}
                  label={texts.commentLabel}
                  fullWidth
                  margin="normal"
                  multiline
                  onChange={handleChange}
                  error={userInfo.errors.comment}
                  helperText={userInfo.errors.comment}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="persons"
                  value={userInfo.persons}
                  label={texts.personLabel}
                  fullWidth
                  margin="normal"
                  multiline
                  onChange={handleChange}
                  error={userInfo.errors.persons}
                  helperText={userInfo.errors.persons}
                />
              </Grid>
              <Grid item xs={12}>
                {menu.orderMethod === "delivery" && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={userInfo.call}
                        onChange={handleChange}
                        name="call"
                        color="primary"
                      />
                    }
                    label={texts.call}
                    style={{ marginBottom: 20 }}
                  />
                )}
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <h2>{texts.paymentMethod}</h2>
              </Grid>
              <Grid item xs={12}>
                <PaymentMethods
                  paymentMethods={paymentMethods}
                  value={userInfo.paymentMethod}
                  onChange={handlePaymentChange}
                  language={language}
                  paymentProvider={state.paymentProvider}
                  error={userInfo.errors.paymentMethod}
                />
                <Voucher language={language} />
                <Coupon language={language} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <h3>{texts.orderMethodTime}</h3>
                <OrderMethodAndTime {...menu} language={language} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {restaurantId !== "da-baggio" &&
                  menu.paymentMethod !== "cash" &&
                  restaurantId !== "salernos-hd" &&
                  (typeof state?.restaurant?.showTip !== "boolean" ||
                    state?.restaurant?.showTip) && <Tip language={language} />}

                <FormControlLabel
                  control={
                    <Switch
                      checked={userInfo.terms}
                      onChange={handleChange}
                      name="terms"
                      color="primary"
                    />
                  }
                  label={
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setTAndC(true);
                      }}
                    >
                      {texts.terms}
                    </a>
                  }
                />
                <FormHelperText
                  error={userInfo.errors.terms}
                  style={{ marginBottom: 20 }}
                >
                  {userInfo.errors.terms}
                </FormHelperText>

                {userInfo.errors.overall && (
                  <Alert style={{ marginBottom: 10 }} severity="error">
                    {userInfo.errors.overall}
                  </Alert>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  style={{ marginBottom: mobile ? "70px" : null }}
                >
                  {userInfo.loading ? (
                    <CircularProgress size="18px" color="inherit" />
                  ) : (
                    `${texts.orderNow} ${stringifyPrice(total, "€")}`
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </main>
        {!mobile && <ShoppingCart page="userInfo" open={true} />}
        <TermsAndConditions open={tAndC} handleClose={() => setTAndC(false)} />
      </section>
    </>
  );
};

export default UserInformation;
