import { useContext, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ApiContext } from "../../../Contexts/ApiContext";
import "./first-steps-modal.css";
import DateAndTime from "./DateAndTime";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { CloseRounded } from "@material-ui/icons";
import { toISODateString } from "react-dates";

const toDateString = (str) => {
  const [yyyy, mm, dd] = str.split("-");

  return `${dd}.${mm}.${yyyy.slice(2, 4)} `;
};

const validateOrderTime = (orderTime) => {
  return (
    orderTime &&
    !orderTime.match(/(20\d{2}\-[0,1]\d\-[0-3]\d[T][0-2]\d\:\d{2})|(now)/gm)
  );
};

const translations = {
  de: {
    step: "Schritt ",
    1: "Für wann möchtest du bestellen?",
    2: "Zum mitnehmen oder nach Hause liefern lassen?",
    orderNow: "So bald wie möglich",
    preorder: "Vorbestellen",
    reopensAt: {
      today: "Heute ab ",
      tomorrow: "Morgen ab ",
      0: "Sonntag ab ",
      1: "Montag ab ",
      2: "Dienstag ab ",
      3: "Mittwoch ab ",
      4: "Donnerstag ab ",
      5: "Freitag ab ",
      6: "Samstag ab ",
      currently_not_open: "Dieser Liefershop ist bis auf weiteres geschlossen",
    },
    closed: "Wir sind ",
    closed2: " wieder für euch da",
    takeAway: "Zum Mitnehmen",
    order: "Liefern lassen",
    calculateDelivery: "Liefergebühr berechnen",
    zipCodeHelper:
      "Geben Sie zum fortfahren Ihre Postleitzahl oder Adresse ein um die Liefergebühr zu berechnen.",
  },
  en: {
    step: "Step ",
    1: "For when would you like to order?",
    2: "Take Away or Delivery?",
    orderNow: "As soon as possible",
    reopensAt: {
      today: "Today from ",
      tomorrow: "Tomorrow from ",
      0: "Sunday from ",
      1: "Monday from ",
      2: "Tuesday from ",
      3: "Wednesday from ",
      4: "Thursday from ",
      5: "Friday from ",
      6: "Saturday from ",
      currently_not_open: "This store is currently closed",
    },
    preorder: "Preorder",
    closed: "We are back for you starting ",
    closed2: "",
    takeAway: "Take Away",
    order: "Liefern lassen",
    calculateDelivery: "Calculate delivery costs",
    zipCodeHelper:
      "Please enter your zipcode or address to calculate the deliveryFee.",
  },
};

const Or = ({ language }) => (
  <>
    <hr />
    <span className="or">{(language = "de" ? "oder" : "or")}</span>
  </>
);

const FirstStep = ({ language = "de", next }) => {
  const {
    state: {
      isOpen,
      reopensAt,
      restaurant = {},
      specialDays = [],
      emergencyClose = null,
      todaysOpeningHours = [],
      shiftVersion,
    },
    setstate,
    menu: { orderTime = "" },
    menuDispatch,
    trackEvent,
    restaurantId,
  } = useContext(ApiContext);

  const texts = translations?.[language] ?? translations.de;

  console.log(reopensAt);

  return (
    <div className="first-steps-modal-body">
      <Button
        variant="contained"
        color="primary"
        disabled={!isOpen}
        fullWidth
        onClick={() => {
          menuDispatch({ type: "orderTime", payload: { date: "now" } });
          trackEvent("OrderTime", { payload: "now" });
          next();
        }}
        style={{ marginBottom: 30 }}
      >
        {texts.orderNow}
      </Button>
      {reopensAt && (
        <small
          style={{ textAlign: "center", display: "block", marginTop: -17 }}
        >
          {texts.closed}
          {typeof reopensAt.day === "string" && reopensAt.day.includes("-")
            ? toDateString(reopensAt?.day)
            : texts.reopensAt[reopensAt?.day]}
          {reopensAt.time}
          {texts.closed2}
        </small>
      )}
      <Or language={language} />
      <DateAndTime
        value={orderTime}
        shiftVersion={shiftVersion}
        restaurantId={restaurantId}
        emergencyClosed={emergencyClose}
        onChange={(date, shift) => {
          trackEvent("OrderTime", { payload: date });
          menuDispatch({ type: "orderTime", payload: { date, shift } });
        }}
        specialDays={specialDays}
        shifts={restaurant.shifts}
        todaysOpeningHours={todaysOpeningHours}
        language={language}
      />
      <Button
        variant="contained"
        disabled={validateOrderTime(orderTime)}
        fullWidth
        onClick={next}
      >
        {texts.preorder}
      </Button>
    </div>
  );
};

const SecondStep = ({ language, next }) => {
  const [distance, setdistance] = useState({ zipCode: "", price: null });

  const {
    state: { restaurant = {} },
    setstate,
    calculateDeliveryCosts,
    menuDispatch,
    trackEvent,
    deliveryMethods = [],
  } = useContext(ApiContext);

  console.log(deliveryMethods);

  const texts = translations[language] ?? translations.de;

  const handleZipCodeChange = ({ target: { name, value } }) =>
    setdistance((dis) => ({
      ...dis,
      [name]: value,
      price: null,
      message: null,
    }));

  const checkDistance = async () => {
    if (!distance.zipCode) {
    } else if (distance.zipCode.length !== 5) {
    }

    const data = await calculateDeliveryCosts(distance.zipCode);

    let message = { de: "", en: "" };

    if (data.error) {
      switch (data.message) {
        case "too_far":
          message = {
            de: "Ihre Adresse liegt außerhalb unseres Lieferradius",
            en: "Your address is outside our delivery-radius",
          };
          break;

        case "not_found":
          message = {
            de: "Postleitzahl nicht gefunden",
            en: "Could not find Zip Code",
          };
          break;

        default:
          message = {
            de: "Es ist ein Fehler aufgetreten",
            en: "An Error occured",
          };
          break;
      }
    } else if (!data.price) {
      message = {
        de: "Die Lieferung ist kostenlos",
        en: "Your delivery is free",
      };
    } else {
      message.de = `Die Liefergebühr beträgt vorraussichtlich ${data.price}€`;
      message.en = `Your delivery fee will most likely be ${data.price}€`;
    }

    setdistance({ ...distance, ...data, message });
  };

  const handleNext = (payload) => (e) => {
    e.preventDefault();

    if (payload === "delivery" && distance.price === null) {
      checkDistance();
    } else {
      menuDispatch({ type: "orderMethod", payload });
      trackEvent("OrderMethod", { payload });
      next();
    }
  };

  const currentTime = new Date().toTimeString();

  return (
    <div className="first-steps-modal-body">
      {deliveryMethods.includes("delivery") && (
        <>
          <form onSubmit={handleNext("delivery")}>
            <h4 style={{ textAlign: "center", marginBottom: 10 }}>
              {texts.calculateDelivery}
            </h4>
            <TextField
              fullWidth
              style={{ marginBottom: 10 }}
              value={distance.zipCode}
              label="Postleitzahl oder Adresse"
              name="zipCode"
              onChange={handleZipCodeChange}
              error={distance.error}
              helperText={
                distance.message
                  ? distance.message[language]
                  : texts.zipCodeHelper
              }
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="checkDistance"
                      onClick={checkDistance}
                    >
                      <ArrowForwardRoundedIcon size="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              disabled={!distance.zipCode || distance.error}
              fullWidth
              onClick={handleNext("delivery")}
              color={distance.price !== null ? "primary" : "default"}
            >
              {texts.order}
            </Button>
          </form>
          <Or language={language} />
        </>
      )}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={
          !deliveryMethods.length || !deliveryMethods.includes("pickup")
        }
        onClick={handleNext("pickup")}
        style={{ marginBottom: 30 }}
      >
        {texts?.takeAway}
      </Button>
    </div>
  );
};

const FirstStepsModal = ({ open, onClose, language = "de" }) => {
  const [step, setstep] = useState(1);

  useEffect(() => {
    setstep(1);
  }, [open]);

  const texts = translations?.[language] ?? translations.de;

  return (
    <Dialog open={open}>
      <div className="first-steps-modal">
        <div style={{ width: "100%" }}>
          <h3>
            {texts.step}
            {step}
          </h3>
          <h4>{texts[step]}</h4>
          <IconButton
            className="first-steps-close"
            size="small"
            onClick={onClose}
          >
            <CloseRounded color="inherit" size="small" />
          </IconButton>
        </div>
        {step === 1 && (
          <FirstStep next={() => setstep(2)} language={language} />
        )}
        {step === 2 && <SecondStep next={onClose} language={language} />}
        <div className="first-steps-modal-dots">
          <span
            className={step === 1 ? "dot active" : "dot"}
            onClick={() => setstep(1)}
          />
          <span className={step === 2 ? "dot active" : "dot"} />
        </div>
      </div>
    </Dialog>
  );
};

export default FirstStepsModal;
