import { useContext, useRef } from 'react';
import { SettingsRounded } from '@material-ui/icons';
import { IconButton, Popover } from '@material-ui/core';
import { ApiContext } from '../../../Contexts/ApiContext';
import Settings from '../../Settings';

const mobile = window.innerWidth < 760;

const SettingsButton = () => {
  const anchorEl = useRef(null);

  const {
    menu: { current = '' },
    menuDispatch
  } = useContext(ApiContext);

  const openSettings = () => menuDispatch({ type: 'openSettings' });
  const closeSettings = () => menuDispatch({ type: 'closeSettings' });

  const open = !mobile && current === 'settings';

  return (
    <>
      <IconButton size="small" onClick={openSettings} ref={anchorEl}>
        <SettingsRounded size="small" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={closeSettings}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Settings />
      </Popover>
    </>
  );
};

export default SettingsButton;
