import { useState, useCallback, useEffect, useContext } from "react";
import { useTransition, animated, config } from "react-spring";
import { Button } from "@material-ui/core";
import { ApiContext } from "../../../Contexts/ApiContext";
import "./AdSlider.css";
import Swipe from "react-easy-swipe";
import { translationsHelper } from "../../../CustomHooks/useTranslations";

const background = (image) => {
  if (!image) return "lightgreen";
  return `linear-gradient(180deg, rgba(245, 245, 245, 0) 50%, #F5F5F5 100%), url('${image}')`;
};

const AnimatedTitle = ({
  title = "",
  description = "",
  transitionProps = {},
  handleClick,
}) => {
  return (
    <div className="animated-wrapper" onClick={handleClick}>
      <span className="animated-title">
        <animated.p style={transitionProps}>{title}</animated.p>
        {description && (
          <animated.p class="animated-button" style={transitionProps}>
            {description}
          </animated.p>
        )}
      </span>
    </div>
  );
};

const AdSlider = ({
  ads = [],
  language = "de",
  timeout = 5000,
  height = null,
  ref = null,
}) => {
  const [index, set] = useState(0);

  const { handleAdClick } = useContext(ApiContext);

  const [to, setto] = useState(null);

  useEffect(() => {
    set(0);
  }, [ads]);

  const handleSwipeLeft = useCallback(
    () => set((state) => (state + 1) % ads.length),
    [ads, set]
  );

  const handleSwipeRight = useCallback(
    () =>
      set((state) => {
        let nV = (state - 1) % ads.length;
        if (nV < 0) {
          return ads.length - 1;
        } else {
          return nV;
        }
      }),
    [ads, set]
  );

  const transitions = useTransition(index, (p) => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  const titleTransitions = useTransition(index, (p) => p, {
    from: { transform: "translateX(26px)", opacity: 0 },
    enter: { transform: "translateX(0px)", opacity: 1 },
    leave: { transform: "translateX(26px)", opacity: 0 },
  });

  useEffect(() => {
    if (ads.length > 1) {
      if (to) clearTimeout(to);
      let t = setTimeout(handleSwipeLeft, timeout);
      setto(t);
    }

    return () => {
      clearTimeout(to);
    };
  }, [index, ads]);

  return (
    <Swipe onSwipeLeft={handleSwipeLeft} onSwipeRight={handleSwipeRight}>
      <div className="ad-slider" style={{ height }} ref={ref}>
        {transitions.map(({ item, props, key }) => {
          const slide = ads[item];

          return (
            <animated.div
              className={`ad-slider-card ad-${slide.id}`}
              key={key}
              style={{
                opacity: props,
              }}
            ></animated.div>
          );
        })}

        {titleTransitions.map(({ item, props, key }) => {
          const slide = ads[item];
          const { title = "", description = "" } = translationsHelper(
            slide.translations,
            language
          );

          return (
            <div className="animated-title-container">
              <AnimatedTitle
                title={title}
                description={
                  description?.replaceAll(/\<\/*[a-z]{1,}\>/gm, "") ||
                  description
                }
                transitionProps={props}
                handleClick={handleAdClick(slide.id)}
              />
            </div>
          );
        })}

        <div className="ad-dot-container">
          {ads.map((s, i) => (
            <span className={`ad-dot ${index === i ? "active" : ""}`} />
          ))}
        </div>
      </div>
    </Swipe>
  );
};

export default AdSlider;
