import { useState, useRef, useContext } from 'react';
import { Popover, Paper, Typography } from '@material-ui/core';
import { ReactComponent as German } from './Icons/german.svg';
import { ReactComponent as English } from './Icons/english.svg';
import { LanguageContext } from '../../Contexts/LanguageContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const LanguageElement = ({
  iconLeft = null,
  iconRight = null,
  onClick = () => {},
  label = '',
  active = false
}) => {
  return (
    <div
      className={active ? 'languageElement active' : 'languageElement'}
      onClick={onClick}
    >
      {iconLeft}
      <Typography style={{ flexGrow: 1 }}>{label}</Typography>
      {iconRight}
      {active && <CheckCircleIcon />}
    </div>
  );
};

const LanguageToggle = () => {
  const { language, handleLanguageChange } = useContext(LanguageContext);
  const anchorEl = useRef(null);
  const [open, setopen] = useState(false);
  const [width, setwidth] = useState(null);

  const handleOpen = () => {
    if (anchorEl.current) {
      setwidth(anchorEl.current.offsetWidth);
    }
    setopen(true);
  };

  return (
    <>
      <div className="languageToggle" ref={anchorEl} onClick={handleOpen}>
        {language === 'de' && (
          <LanguageElement
            iconLeft={<German />}
            iconRight={<ArrowDropDownIcon />}
            label="Deutsch"
          />
        )}
        {language === 'en' && (
          <LanguageElement
            iconLeft={<English />}
            iconRight={<ArrowDropDownIcon />}
            label="English"
          />
        )}
      </div>
      <Popover
        open={open}
        onClose={() => setopen(false)}
        anchorEl={anchorEl.current}
      >
        <Paper style={{ width }}>
          <LanguageElement
            onClick={() => handleLanguageChange('de')}
            iconLeft={<German />}
            label="Deutsch"
            active={language === 'de'}
          />
          <LanguageElement
            onClick={() => handleLanguageChange('en')}
            iconLeft={<English />}
            label="English"
            active={language === 'en'}
          />
        </Paper>
      </Popover>
    </>
  );
};

export default LanguageToggle;
