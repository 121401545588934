import { useContext, useEffect, useState } from 'react';
import GastronautPayment from '../../../GastronautPayment';
import { ApiContext } from '../../../Contexts/ApiContext';
import { LanguageContext } from '../../../Contexts/LanguageContext';
import ShoppingCart from '../Desktop/ShoppingCart';
import { IconButton } from '@material-ui/core';
import { ArrowBackRounded } from '@material-ui/icons';

const Payment = ({ history, match }) => {
  const [isSuccess, setisSuccess] = useState(false);
  const { payableItemId, restaurantId, paymentMethod } = match.params;
  const { menu, setrestaurantId, menuDispatch, state } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);

  const goBack = () => history.push(`/${restaurantId}/info`);

  useEffect(() => {
    setrestaurantId(restaurantId);
  }, [restaurantId]);

  useEffect(() => {
    if (state.loaded && (!menu.cart || !menu.cart.length)) {
      menuDispatch({ type: 'reloadSavedMenu' });
    }
  }, [menu.cart, state.loaded]);

  const mobile = window.innerWidth < 760;

  if(isSuccess) {
    history.push(`/${restaurantId}/success`)
  }

  return (
    <section className="user-information-page">
      <div className="sub-header">
        {!isSuccess && (
          <IconButton
            size="small"
            onClick={goBack}
            style={{ marginRight: 10 }}
            disabled={!!isSuccess}
          >
            <ArrowBackRounded />
          </IconButton>
        )}
        <span>Bezahlung</span>
      </div>
      <main>
        <GastronautPayment
          restaurantId={restaurantId}
          payableItemId={payableItemId}
          returnUrl={window.location.href}
          language={language}
          paymentMethods={[paymentMethod]}
          requiredFields={[]}
          hideCustomerInfo
          hidePurchaseInfo
          onPaymentSuccess={setisSuccess}
          customer={menu.customer}
        />
      </main>
      {!mobile && <ShoppingCart page="payment" open={true} />}
    </section>
  );
};

export default Payment;
