import {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useState
} from 'react';
import { ApiContext } from '../../../Contexts/ApiContext';
import { LanguageContext } from '../../../Contexts/LanguageContext';
import CategoryList from './CategoryList';
import ShoppingCart from './ShoppingCart';
import Category from './Category';
import FooterDesktop from './FooterDesktop';
import FirstStepsModal from './FirstStepsModal';
import Footer from '../../Footer';
import AdSlider from './AdSlider';
import ImageModal from './ImageModal';
import OptionModal from './OptionModal';


const Desktop = ({ history, match }) => {
  const { restaurantId } = match.params;
  const scrollRef = useRef(null);
  const adRef = useRef(null);
  const {
    setrestaurantId,
    filteredCategories: categories,
    state: { highlights = [] },
    setcurrentCategory,
    modal,
    setmodal,
    ads = [],
    cartOpen
  } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);

  const mobile = window.innerWidth < 760;

  useEffect(() => {
    setrestaurantId(restaurantId);
  }, [restaurantId]);

  // useEffect(() => {
  //   window.location.replace('https://www.ghostkitchen-heidelberg.de/neo-heidelberg/charity');
  // }, [])

  const scrollListener = useCallback(
    e => {

      let obj = {};

      if (scrollRef.current) {
        let children = scrollRef.current.children[0].children

        const offset = mobile ? 80 : 150;

        for (const el of children) {
          if(el.id.startsWith('cat')) {
            obj[el.id.replace('cat-', '')] = { start: el.offsetTop - offset, close: el.offsetTop + el.offsetHeight - offset }
          }
        }
      }
      
      if(e.target.id !== 'main') return;
      
      let scrollPos = mobile ? window.scrollY : e.target.scrollTop;
      
      let keys = Object.keys(obj);

      let current = keys.find(t => obj[t].start <= scrollPos && obj[t].close >= scrollPos);

      if(current) {
        setcurrentCategory(current) 
      };
    },
    [scrollRef, categories]
  );

  useEffect(() => {
    if (mobile) {
      document.removeEventListener('scroll', scrollListener);
      document.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (mobile) {
        document.removeEventListener('scroll', scrollListener);
      }
    };
  }, [scrollListener]);

  useEffect(() => {
    let styles = '';

    let el = document.createElement('style');

    if (highlights.length) {
      highlights.forEach(({ id, image }) => {
        styles += `.highlight-${id} { background-image: linear-gradient(180deg, rgba(245, 245, 245, 0) 50%, #F5F5F5 100%), url('${image}'); }`;
      });
    }

    if(ads.length) {
      ads.forEach(({ id, image }) => {
        styles += `.ad-${id} { background-image: linear-gradient(175deg, rgb(45, 45, 45, 0.3) 0%, rgba(45, 45, 45, 0.3) 100%), url('${image}'); }`;
      });
    }

    el.innerText = styles;

    if(styles) document.body.prepend(el);

    return () => {};
  }, [highlights.length, ads.length]);

  return (
    <>
      <section className={ cartOpen ? "main-page open" : "main-page"} id="section">
        <CategoryList hasScrollRef={!!scrollRef.current} />
        <main id="main" ref={scrollRef} onScroll={scrollListener}>
          <div style={{ boxShadow: `0 0 10px 2px #0000000d` }}>
            {ads && !!ads.length && <AdSlider ads={ads} language={language} ref={adRef} />}
            {categories.map(cat => (
              <Category key={cat.id} data={cat} language={language} />
            ))}
            <FooterDesktop />
          </div>
        </main>
        {!mobile && <ShoppingCart />}
      </section>
      <FirstStepsModal
        open={modal}
        onClose={() => setmodal(false)}
        language={language}
      />
      <Footer />
      <ImageModal />
      <OptionModal 
        language={language}
      />
    </>
  );
};

export default Desktop;
