import React, { useContext } from 'react';
import { ApiContext } from '../../../Contexts/ApiContext';
import { IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const TermsAndConditions = ({ open = false, handleClose = () => {} }) => {
  const {
    state: { restaurant = {} },
    restaurantId
  } = useContext(ApiContext);

  if (!open) return <></>;

  return (
    <div className="termsAndConditionsContainer">
      <div className="termsAndConditions">
        <IconButton onClick={handleClose} id="termsAndConditionsClose">
          <CloseRounded />
        </IconButton>
        <h2>Allgemeine Geschäftsbedingungen</h2>
        <h3>§ 1 Geltung, Begriffsdefinitionen</h3>
        <p>
          (1) {restaurant.name}, {restaurant.restaurantAddress}, Deutschland (im Folgenden: „wir“ oder „Restaurant“) betreibt unter der Webseite
          https://lieferdienst.gastronaut.ai/{restaurantId} einen Online-Shop
          für Waren. Die nachfolgenden allgemeinen Geschäftsbedingungen gelten
          für alle Leistungen zwischen uns und unseren Kunden (im Folgenden:
          „Kunde“ oder „Sie“) in ihrer zum Zeitpunkt der Bestellung gültigen
          Fassung, soweit nicht etwas anderes ausdrücklich vereinbart wurde. (2)
          „Verbraucher“ im Sinne dieser Geschäftsbedingungen ist jede natürliche
          Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend
          weder ihrer gewerblichen noch ihrer selbständigen beruflichen
          Tätigkeit zugerechnet werden können. „Unternehmer“ ist eine natürliche
          oder juristische Person oder eine rechtsfähige Personengesellschaft,
          die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen
          oder selbständigen beruflichen Tätigkeit handelt, wobei eine
          rechtsfähige Personengesellschaft eine Personengesellschaft ist, die
          mit der Fähigkeit ausgestattet ist, Rechte zu erwerben und
          Verbindlichkeiten einzugehen.
        </p>

        <h3>§ 2 Zustandekommen der Verträge, Speicherung des Vertragstextes</h3>
        <p>
          (1) Die folgenden Regelungen über den Vertragsabschluss gelten für
          Bestellungen über unseren Online-Shop unter
          https://lieferdienst.gastronaut.ai/{restaurantId}. (2) Unsere
          Produktdarstellungen im Internet sind unverbindlich und kein
          verbindliches Angebot zum Abschluss eines Vertrages. (3) Bei Eingang
          einer Bestellung in unserem Online-Shop gelten folgende Regelungen:
          Der Kunde gibt ein bindendes Vertragsangebot ab, indem er die in
          unserem Online-Shop vorgesehene Bestellprozedur erfolgreich
          durchläuft. Die Bestellung erfolgt in folgenden Schritten: Auswahl der
          gewünschten Ware, Hinzufügen der Produkte durch Anklicken des
          entsprechenden Buttons (z.B. „In den Warenkorb“, „In die
          Einkaufstasche“ o.ä.), Prüfung der Angaben im Warenkorb, Aufrufen der
          Bestellübersicht durch Anklicken des entsprechenden Buttons (z.B.
          „Bestellen“, „Weiter zur Zahlung“, „Zur Bestellübersicht“ o.ä.),
          Eingabe/Prüfung der Adress- und Kontaktdaten, Auswahl der Zahlungsart,
          Bestätigung der AGB und Widerrufsbelehrung, Abschluss der Bestellung
          durch Betätigung des Buttons „Jetzt kostenpflichtig bestellen“. Dies
          stellt Ihre verbindliche Bestellung dar. Der Vertrag kommt zustande,
          indem Ihnen innerhalb von drei Werktagen an die angegebene
          E-Mail-Adresse eine Bestellbestätigung von uns zugeht. (4) Im Falle
          des Vertragsschlusses kommt der Vertrag mit Restaurant. (5) Vor der Bestellung können die Vertragsdaten über die
          Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert
          werden. Die Abwicklung der Bestellung und Übermittlung aller im
          Zusammenhang mit dem Vertragsschluss erforderlichen Informationen,
          insbesondere der Bestelldaten, der AGB und der Widerrufsbelehrung,
          erfolgt per E-Mail nach dem Auslösen der Bestellung durch Sie, zum
          Teil automatisiert. Wir speichern den Vertragstext nach
          Vertragsschluss nicht. (6) Eingabefehler können mittels der üblichen
          Tastatur-, Maus- und Browser-Funktionen (z.B. »Zurück-Button« des
          Browsers) berichtigt werden. Sie können auch dadurch berichtigt
          werden, dass Sie den Bestellvorgang vorzeitig abbrechen, das
          Browserfenster schließen und den Vorgang wiederholen. (7) Die
          Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit
          dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail
          zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die
          von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der
          Empfang der E-Mails technisch sichergestellt und insbesondere nicht
          durch SPAM-Filter verhindert wird.
        </p>

        <h3>
          § 3 Gegenstand des Vertrages und wesentliche Merkmale der Produkte
        </h3>
        <p>
          (1) Bei unserem Online-Shop ist Vertragsgegenstand: Der Verkauf von
          Waren. Die konkret angebotenen Waren können Sie unseren Artikelseiten
          entnehmen. (2) Die wesentlichen Merkmale der Ware finden sich in der
          Artikelbeschreibung. (3) Für den Verkauf digitaler Produkte gelten die
          aus der Produktbeschreibung ersichtlichen oder sich sonst aus den
          Umständen ergebenden Beschränkungen, insbesondere zu Hard- und/oder
          Softwareanforderungen an die Zielumgebung. Wenn nicht ausdrücklich
          etwas anderes vereinbart ist, ist Vertragsgegenstand nur die private
          und gewerbliche Nutzung der Produkte ohne das Recht zur
          Weiterveräußerung oder Unterlizensierung.
        </p>

        <h3>§ 4 Preise, Versandkosten und Lieferung</h3>
        <p>
          (1) Die in den jeweiligen Angeboten angeführten Preise sowie die
          Versandkosten sind Gesamtpreise und beinhalten alle Preisbestandteile
          einschließlich aller anfallenden Steuern. (2) Der jeweilige Kaufpreis
          ist vor der Lieferung des Produktes zu leisten (Vorkasse), es sei
          denn, wir bieten ausdrücklich den Kauf auf Rechnung an. Die Ihnen zur
          Verfügung stehenden Zahlungsarten sind unter einer entsprechend
          bezeichneten Schaltfläche im Online-Shop oder im jeweiligen Angebot
          ausgewiesen. Soweit bei den einzelnen Zahlungsarten nicht anders
          angegeben, sind die Zahlungsansprüche sofort zur Zahlung fällig. (3)
          Zusätzlich zu den angegebenen Preisen können für die Lieferung von
          Produkten Versandkosten anfallen, sofern der jeweilige Artikel nicht
          als versandkostenfrei ausgewiesen ist. Die Versandkosten werden Ihnen
          auf den Angeboten, ggf. im Warenkorbsystem und auf der
          Bestellübersicht nochmals deutlich mitgeteilt. (4) Alle angebotenen
          Produkte sind, sofern nicht in der Produktbeschreibung deutlich anders
          angegeben, sofort versandfertig (Lieferzeit: Richtet sich nach der
          Entfernung zwischen dem Produktionsort in der Alten Glockengießerei 9,
          69115 Heidelberg und dem Zustellort nach dem Eingang der Zahlung). (5)
          Es bestehen die folgenden Liefergebietsbeschränkungen: Die Lieferung
          erfolgt in folgende Länder: Deutschland.
        </p>

        <h3>§ 5 Zurückbehaltungsrecht, Eigentumsvorbehalt</h3>
        <p>
          (1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich
          um Forderungen aus demselben Vertragsverhältnis handelt. (2) Die Ware
          bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.
        </p>

        <h3>§ 6 Widerrufsrecht</h3>
        <p>
          Als Verbraucher haben Sie ein Widerrufsrecht. Dieses richtet sich nach
          unserer Widerrufsbelehrung.
        </p>

        <h3>§ 7 Haftung</h3>
        <p>
          (1) Vorbehaltlich der nachfolgenden Ausnahmen ist unsere Haftung für
          vertragliche Pflichtverletzungen sowie aus unerlaubter Handlung auf
          Vorsatz oder grobe Fahrlässigkeit beschränkt. (2) Wir haften bei
          leichter Fahrlässigkeit im Falle der Verletzung des Lebens, des
          Körpers, der Gesundheit oder bei Verletzung einer vertragswesentlichen
          Pflicht unbeschränkt. Wenn wir durch leichte Fahrlässigkeit mit der
          Leistung in Verzug geraten sind, wenn die Leistung unmöglich geworden
          ist oder wenn wir eine vertragswesentliche Pflicht verletzt haben, ist
          die Haftung für darauf zurückzuführende Sach- und Vermögensschäden auf
          den vertragstypisch vorhersehbaren Schaden begrenzt. Eine
          vertragswesentliche Pflicht ist eine solche, deren Erfüllung die
          ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht,
          deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf
          deren Einhaltung Sie regelmäßig vertrauen dürfen. Dazu gehört
          insbesondere unsere Pflicht zum Tätigwerden und der Erfüllung der
          vertraglich geschuldeten Leistung, die in § 3 beschrieben wird.
        </p>

        <h3>§ 8 Vertragssprache</h3>
        <p>Als Vertragssprache steht ausschließlich Deutsch zur Verfügung.</p>

        <h3>§ 9 Gewährleistung</h3>
        <p>
          (1) Die Gewährleistung richtet sich nach den gesetzlichen
          Bestimmungen. (2) Gegenüber Unternehmern beträgt die
          Gewährleistungsfrist auf gelieferte Sachen 12 Monate. (3) Als
          Verbraucher werden Sie gebeten, die Sache/die digitalen Güter oder die
          erbrachte Dienstleistung bei Vertragserfüllung umgehend auf
          Vollständigkeit, offensichtliche Mängel und Transportschäden zu
          überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich
          mitzuteilen. Kommen Sie dem nicht nach, hat dies natürlich keine
          Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.
        </p>

        <h3>§ 10 Schlussbestimmungen</h3>
        <p>
          (1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl
          nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des
          Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz
          nicht entzogen wird (Günstigkeitsprinzip). (2) Die Bestimmungen des
          UN-Kaufrechts finden ausdrücklich keine Anwendung. (3) Sofern es sich
          beim Kunden um einen Kaufmann, eine juristische Person des
          öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen
          handelt, ist Gerichtsstand für alle Streitigkeiten aus
          Vertragsverhältnissen zwischen dem Kunden und dem Anbieter der Sitz
          des Anbieters.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
