import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useContext } from 'react'
import { ApiContext } from '../../../Contexts/ApiContext'

const ImageModal = () => {
    const { imageModal, closeImageModal } = useContext(ApiContext);

    const open = !!imageModal;

    const handleAwayClick = e => {
        if(e.target.id !== "imageModalAwayClick" ) return;
        closeImageModal();
    }

    return (
        <div style={{ display: open ? 'flex' : 'none' }} className="imageModal" id="imageModalAwayClick" onClick={handleAwayClick} >
            <IconButton style={{ color: 'white' }} onClick={closeImageModal} size="large">
                <Close color="inherit" size="large" />
            </IconButton>
            <img src={imageModal} />
        </div>
    )
}

export default ImageModal
