import { Box, Button } from '@material-ui/core';
import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiContext, server } from '../../../Contexts/ApiContext';
import { LanguageContext } from '../../../Contexts/LanguageContext';
import { ErrorMessage } from '../../../GastronautPayment/Components';
import Loading from '../Loading';

const Redirect = ({ history, match }) => {

  const { restaurantId, payableItemId } = match.params;

  const { language } = useContext(LanguageContext);
  const { state, setstate } = useContext(ApiContext);

  const [error, seterror] = useState(null);

  useEffect(() => {

    if(restaurantId && payableItemId) {
      server.get(`v03/takeAway/${restaurantId}/redirect/${payableItemId}`).then(({ data }) => {

        if(data.error) {
          seterror({ message: data.message, paymentUrl: data.paymentUrl });
          setstate({ restaurant: data.restaurant, loaded: true, unavailable: [] });
          return
        }

        setstate({ restaurant: data.restaurant, custom: data.custom, loaded: true });
        history.push(`/${restaurantId}/success`);

      }).catch((err) => {
        history.push('/error');
      })
    }

  }, [restaurantId, payableItemId]);

  console.log({ language, restaurantId, payableItemId });

  if(!!error) {
    return (
      <Box>
      <ErrorMessage error={error.message[language]} language={language} />
      {!!error.paymentUrl && (
        <Button href={error.paymentUrl}>
          {language === 'de' ? 'Erneut versuchen' : 'Try Again' }
        </Button>
      )}
    </Box>
    )
  }

  return (
    <Loading loading />
  )
}

export default Redirect