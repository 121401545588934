import React from "react";
import "./paymentCards.css";
import Alert from "@material-ui/lab/Alert";

const DEFAULT_PAYMENT_OPTIONS = ["card", "sepa_debit", "sofort"];

const translations = {
  de: {
    cash: "Bargeld",
    card: "Kreditkarte",
    sepa_debit: "SEPA",
    sofort: "SOFORT",
    mollie: "Online",
  },
  en: {
    cash: "Cash",
    card: "Credit Card",
    sepa_debit: "SEPA",
    sofort: "SOFORT",
    mollie: "Online",
  },
};

const PaymentMethods = ({
  paymentMethods: defaultPaymentMethods = DEFAULT_PAYMENT_OPTIONS,
  value = "",
  onChange = () => {},
  language = "de",
  error = false,
  paymentProvider = "stripe",
}) => {
  const texts = translations[language];

  const handleClick = (id) => () => onChange(id);

  const paymentMethods =
    paymentProvider === "stripe"
      ? defaultPaymentMethods
      : defaultPaymentMethods.includes("cash")
      ? ["mollie", "cash"]
      : ["mollie"];

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <div className="payment-card-container">
        {paymentMethods.map((id) => (
          <div
            key={id}
            onClick={handleClick(id)}
            className={
              value === id
                ? `payment-card ${id} selected`
                : `payment-card ${id}`
            }
          >
            <img src={`/paymentMethods/${id}.png`} />
            <span label={texts[id]}>{texts[id]}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaymentMethods;
