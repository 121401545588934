import { useContext, useMemo } from "react";
import { LanguageContext } from "../../Contexts/LanguageContext";
import { ApiContext } from "../../Contexts/ApiContext";
import { withRouter } from "react-router-dom";
import { stringifyPrice } from "../../helperFunctions";

import { ReactComponent as Cart } from "./cart.svg";
import { ArrowBackRounded, MenuRounded } from "@material-ui/icons";
import { IconButton, Button, Typography } from "@material-ui/core";

const translations = {
  de: {
    button: "Jetzt bestellen",
    minOrder: "Mindest-Bestellwert",
  },
  en: {
    button: "Order Now",
    minOrder: "Min. Order Value",
  },
};

const regularStyle = {
  fontSize: "18px",
  textTransform: "none",
  color: "#000",
  opacity: 0.5,
};

const activeStyle = {
  fontSize: "18px",
  textTransform: "none",
  color: "#000",
  fontWeight: "bold",
  opacity: 1,
};

const RegularMenu = ({ history, location }) => {
  const { language } = useContext(LanguageContext);
  const {
    restaurantId,
    menu,
    menuDispatch,
    state: { restaurant = {} },
    calculations: { total, subTotal },
    cartItems,
    minOrderValue = 0,
  } = useContext(ApiContext);

  const { type } = menu;

  const homePath = location.pathname === `/${restaurantId}`;

  const openCart = () => history.push(`/${restaurantId}/cart`);
  const goBack = () => history.push(`/${restaurantId}`);
  const openInfo = () => history.push(`/${restaurantId}/cart`);

  return (
    <div className="footer-regular">
      {!homePath && (
        <IconButton onClick={goBack}>
          <ArrowBackRounded style={{ fill: "#121212" }} />
        </IconButton>
      )}
      {homePath && (
        <IconButton onClick={() => menuDispatch({ type: "openSettings" })}>
          <MenuRounded style={{ fill: "#121212" }} />
        </IconButton>
      )}

      {(!minOrderValue || subTotal >= minOrderValue) && (
        <Button onClick={openInfo}>
          {translations[language]?.button} {stringifyPrice(total, "€")}
        </Button>
      )}
      {subTotal < minOrderValue && (
        <Typography>
          {translations[language].minOrder} {stringifyPrice(minOrderValue, "€")}
        </Typography>
      )}
      <IconButton onClick={openCart}>
        <div className="wishlist-footer-button">
          <span>{!!cartItems && cartItems}</span>
          <Cart />
        </div>
      </IconButton>
    </div>
  );
};

export default withRouter(RegularMenu);
