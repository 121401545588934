import { useState, useContext } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { ArrowForwardRounded } from '@material-ui/icons';
import { ApiContext } from '../../../Contexts/ApiContext';
import { stringifyPrice } from '../../../helperFunctions';
import Alert from '@material-ui/lab/Alert';

const translations = {
  de: {
    title: 'Rabattcode verwenden',
    description: '',
    not_found: 'Keinen Rabat mit diesem Code gefunden',
    missing_credentials: 'Nicht vollständig',
    label: 'RabattCode'
  },
  en: {
    title: 'Redeem Coupon',
    description: '',
    not_found: 'Could not find a coupon with this id',
    missing_credentials: 'The code is not valid',
    label: 'Coupon Code'
  }
};

const Voucher = ({ language = 'de' }) => {
  const [couponId, setcouponId] = useState('');
  const [message, setmessage] = useState({ msg: '', error: false });
  const { handleCoupon, menu } = useContext(ApiContext);

  const handleChange = ({ target: { value } }) => setcouponId(value);

  const texts = translations[language];

  const handleSubmit = async e => {
    e.preventDefault();
    const { error, amount, type } = await handleCoupon(couponId);
    if (!error && !!amount) {
      let msg = '';

      if (language === 'de') {
        msg = `Der Gutschein im Wert von ${stringifyPrice(
          amount,
          type === 'percentage' ? '%' : '€'
        )} wurde dir gutgeschrieben`;
      } else {
        msg = `You successfully added a voucher with the amount of ${stringifyPrice(
          amount,
          type === 'percentage' ? '%' : '€'
        )} on it.`;
      }
      setmessage({ msg, error: false });
    } else {
      setmessage({ msg: texts[error], error: true });
    }
  };

  return (
    <form className="voucher-container" onSubmit={handleSubmit}>
      <h3>{texts.title}</h3>
      <p>{texts.description}</p>
      {(message.error || menu.coupon) && message.msg && (
        <Alert
          severity={message.error ? 'error' : 'success'}
          onClose={message.error ? () => setmessage({}) : null}
          style={{ marginBottom: 10 }}
        >
          {message.msg}
        </Alert>
      )}
      <div className="voucher-input">
        <TextField
          name="couponId"
          value={couponId}
          onChange={handleChange}
          label={texts.label}
          variant="outlined"
          margin="dense"
          error={message.error}
        />
        <IconButton type="submit" onClick={handleSubmit}>
          <ArrowForwardRounded />
        </IconButton>
      </div>
    </form>
  );
};

export default Voucher;
