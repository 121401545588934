import { useContext, useMemo, useState } from 'react';
import { ApiContext } from '../../../Contexts/ApiContext';
import { Add } from '@material-ui/icons';

const AddToCart = ({ id, style = {}, language = 'de', hasOptions = false }) => {
  const [hover, sethover] = useState(false);
  const {
    state: { restaurant: { colorPalette = {} } },
    menuDispatch,
    setmodal,
    setOptionModal,
    menu: { cart = [], orderMethod }
  } = useContext(ApiContext);

  const buttonText = useMemo(
    () => (language ? 'Zum Warenkorb' : 'Add to Cart'),
    [language]
  );

  const handleClick = () => {
    if(!orderMethod) {
      setmodal(true);
    } else if(hasOptions) {
      setOptionModal(id);
    } else {
      menuDispatch({ type: 'addToCart', payload: id });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
      <button className={`cart-button`} style={style} onClick={handleClick} onMouseEnter={e => sethover(true) } onMouseLeave={e => sethover(false) } >
        <div className="cart-button-cover" style={{ background: hover ? colorPalette.primaryColor : null }}  />
        <Add />
        <span className="clicked">+1</span>
      </button>
    </div>
  );
};

export default AddToCart;
