import React, { useMemo, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from '../../GastronautPayment/Components/main.module.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { LanguageContext } from '../../Contexts/LanguageContext';
import { ApiContext } from '../../Contexts/ApiContext';
import { dateHelper } from '../../helperFunctions';

const stringifyDeliveryTime = (deliveryTime = '', language) => {
  if(!deliveryTime) return '';

  const [date, time] = deliveryTime.split('T');

  if(date === dateHelper()) {
    return `${language === 'de' ? 'Heute um' : 'Today at'} ${time}`;
  } else {
    let [, mm, dd] = date.split('-');
    return `${dd}.${mm}, ${time}`
  }
}

const translations = {
  de: {
    header: 'Ihre Bestellung war Erfolgreich',
    description: 'Sie erhalten in kürze eine Email von uns.',
    delivery: 'Auslieferung',
    pickup: 'Selbst Abholung'
  },
  en: {
    header: 'Your order was successful',
    description: 'will get booked from your bank account within the next days.',
    delivery: 'Delivery',
    pickup: 'Pick Up at Restaurant'
  }
};

const SuccessMessage = ({ history, match }) => {
  const { restaurantId } = match.params;
  

  const { language } = useContext(LanguageContext);
  const { state, menu = {} } = useContext(ApiContext);

  const { custom = {} } = state;

  console.log(custom);

  const deliveryTime = stringifyDeliveryTime(custom.deliveryTime || menu.deliveryTime, language); 

  useEffect(() => {
    if (!state.loaded) {
      history.push(`/${restaurantId}`);
    }
  }, [restaurantId]);

  const translation = useMemo(() => translations[language], [language]);

  return (
    <Box className={styles.successContainer}>
      <CheckCircleIcon color="primary" />
      <Typography variant="h4">{translation.header}</Typography>
      <Typography variant="body1">{translation.description}</Typography>
      <Typography variant="h6">{translation[custom.orderMethod] || ''}</Typography>
      <Typography variant="body1">{deliveryTime}</Typography>
    </Box>
  );
};

export default SuccessMessage;
