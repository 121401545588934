import { useEffect, useState, useContext } from "react";
import LanguageContextProvider from "./Contexts/LanguageContext";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "./Components/Header";
import Loading from "./Components/Screens/Loading";
import AllergenModal from "./Components/AllergenModal";
import ApiContextProvider, { ApiContext } from "./Contexts/ApiContext";
import Desktop from "./Components/Screens/Desktop";
import UserInformation from "./Components/Screens/UserInformation";
import Payment from "./Components/Screens/Payment";
import Success from "./Components/Screens/Success";
import ShoppingCart from "./Components/Screens/Desktop/ShoppingCart";
import ReactPixel from "react-facebook-pixel";
import useMetaTexts from "./CustomHooks/useMetaTexts";
import CharityClosed from "./Components/CharityClosed";
import Redirect from "./Components/Screens/Mollie/Redirect";

const shadows = Array.from(Array(25), () => "none");

const checkColor = (colorCode) => {
  return colorCode.startsWith("#") ? colorCode : `#${colorCode}`;
};

const ShoppingCartWrapper = ({
  history,
  match: {
    params: { restaurantId },
  },
}) => {
  const { state } = useContext(ApiContext);

  const goBack = () => history.push(`/${restaurantId}`);

  useEffect(() => {
    if (!state.loaded) {
      goBack();
    }
  }, [restaurantId]);

  return (
    <>
      <ShoppingCart mobile={true} history={history} />
    </>
  );
};

const ErrorPage = ({ history }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <img src="/404.png" style={{ maxWidth: "90vw" }} />
        <h1 style={{ textAlign: "center", marginTop: 10 }}>
          404 - Page not found
        </h1>
        <p style={{ textAlign: "center", marginTop: 10 }}>
          Your link might be broken
        </p>
      </div>
    </div>
  );
};

const Root = ({ history }) => {
  if (
    window.location.hostname.includes("ghostkitchen-heidelberg.de") ||
    window.location.hostname.includes("localhost")
  ) {
    history.push("/neo-heidelberg");
  }

  return <></>;
};

function App() {
  const [colorPalette, setColorPalette] = useState({
    primaryColor: "#6FCF97",
    onPrimary: "#121212",
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: checkColor(colorPalette?.primaryColor || "#6FCF97"),
        contrastText: checkColor(colorPalette?.onPrimary || "#121212"),
      },
    },
    shadows,
    typography: {
      htmlFontSize: 16,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: "1.75rem",
        lineHeight: 1.167,
        letterSpacing: "-0.01562em",
      },
      h2: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: "1.5rem",
        lineHeight: 1.167,
        letterSpacing: "-0.00833em",
      },
      h3: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: "1.375rem",
        lineHeight: 1.167,
        letterSpacing: "0em",
      },
      h4: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: "1.25rem",
        lineHeight: 1.167,
        letterSpacing: "0.00735em",
      },
      h5: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: "1.125rem",
        lineHeight: 1.167,
        letterSpacing: "0em",
      },
      h6: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: 1.167,
        letterSpacing: "0.0075em",
      },
    },
  });

  const [pixel, setpixel] = useState(null);
  const [meta, setmeta] = useState({});

  useEffect(() => {
    console.log({ pixel });

    if (pixel) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };

      ReactPixel.init(pixel, advancedMatching, options);
    }
  }, [pixel]);

  useEffect(() => {
    ReactPixel.pageView();
  }, [document.location.href]);

  const trackEvent = (event, data) => {
    /*
       'TimeLookUp', { date: '2020-01-01', occassion: 'general', guests: 4 };
       'CreateReservation', { date: '2020-01-01', occassion: 'general', guests: 4, time: '18:00', language: 'de' };
       'CancelReservation', {};
       'EditReservation', {};
       'ConfirmedReservation', {};
       'Review', { feedback: 3 // 1-5 Bewertung };
     */

    console.log("Pixel", event, pixel);

    if (!pixel) return;

    if (event === "Purchase") {
      ReactPixel.track(event, data);
      console.log("purchase tracked");
    }

    ReactPixel.trackCustom(event, data);
    return;
  };

  useMetaTexts(meta);

  return (
    <ThemeProvider theme={theme}>
      <ApiContextProvider
        setColorPalette={setColorPalette}
        setPixel={setpixel}
        pixel={pixel}
        trackEvent={trackEvent}
        setmeta={setmeta}
      >
        <LanguageContextProvider>
          <div className="App" id="testMobile">
            <BrowserRouter>
              <Loading />
              <Header />
              <Switch>
                <Route path="/" exact="true" component={Root} />
                <Route path="/error" exact="true" component={ErrorPage} />
                <Route path="/:restaurantId" exact="true" component={Desktop} />
                <Route
                  path="/:restaurantId/cart"
                  exact="true"
                  component={ShoppingCartWrapper}
                />
                <Route
                  path="/:restaurantId/info"
                  exact="true"
                  component={UserInformation}
                />
                <Route
                  path="/:restaurantId/mollie/:payableItemId"
                  exact="true"
                  component={Redirect}
                />
                <Route
                  path="/:restaurantId/success"
                  exact="true"
                  component={Success}
                />
                <Route
                  path="/:restaurantId/:payableItemId/:paymentMethod"
                  exact="true"
                  component={Payment}
                />

                <Route
                  path="/neo-heidelberg/charity"
                  exact
                  component={CharityClosed}
                />
              </Switch>
              <AllergenModal />
            </BrowserRouter>
          </div>
        </LanguageContextProvider>
      </ApiContextProvider>
    </ThemeProvider>
  );
}

export default App;
