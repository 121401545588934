import React, { useContext } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { ApiContext } from '../../Contexts/ApiContext';
import { LanguageContext } from '../../Contexts/LanguageContext';
import LanguageToggle from './LanguageToggle';
import Preferences from './Preferences';
import Allergens from './Allergens';

const Settings = () => {
  const { menuDispatch } = useContext(ApiContext);
  const { texts } = useContext(LanguageContext);
  return (
    <div className="footer-settings">
      <div className="footer-settings-header">
        <IconButton onClick={() => menuDispatch({ type: 'closeSettings' })}>
          <CloseRounded />
        </IconButton>
        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
          {texts.settingsHeader}
        </Typography>
      </div>
      <div className="footer-settings-body">
        <LanguageToggle />
        <Preferences />
        <Allergens />
      </div>
    </div>
  );
};

export default Settings;
