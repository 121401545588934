import { useContext } from "react";
import { ApiContext } from "../../Contexts/ApiContext";
import { CircularProgress } from "@material-ui/core";

const Loading = ({ loading = false }) => {
  const { state } = useContext(ApiContext);

  if (
    !loading && 
    (state.loaded ||
    window.location.pathname.includes("error") ||
    window.location.pathname.includes("charity"))
  )
    return <></>;
  return (
    <div className="loading">
      <CircularProgress />
    </div>
  );
};

export default Loading;
