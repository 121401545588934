import { useContext, useEffect, useMemo } from "react";
import { ApiContext } from "../../../Contexts/ApiContext";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { Typography } from "@material-ui/core";
import MealItem from "../Meals/MealItem";
import SubCategoryImage from "../Meals/SubCategoryImage";
import useTranslations from "../../../CustomHooks/useTranslations";

const matchPreferences = (
  { vegan, alcoholic, vegetarian },
  preferences = []
) => {
  if (!preferences.length) return true;

  if (preferences.includes("noAlc") && alcoholic) return false;

  if (preferences.includes("vegan") && !vegan) return false;

  if (preferences.includes("veggie") && !vegetarian) return false;

  return true;
};

const sortByTag = (a, b) => {
  let aTag = a.translations.de.tags ? a.translations.de.tags : "";
  let bTag = b.translations.de.tags ? b.translations.de.tags : "";

  if (aTag === bTag) {
    return 0;
  }

  aTag = aTag.split("").map((a) => a.charCodeAt(0));
  bTag = bTag.split("").map((a) => a.charCodeAt(0));

  if (aTag.length >= bTag.length) {
    return (
      parseInt(bTag.slice(0, bTag.length - 1).join("")) -
      parseInt(aTag.slice(0, bTag.length - 1).join(""))
    );
  } else {
    return (
      parseInt(bTag.slice(0, aTag.length - 1).join("")) -
      parseInt(aTag.slice(0, aTag.length - 1).join(""))
    );
  }
};

const sortByPrice = (a = {}, b = {}) => {
  if (a.price && b.price) return a.price - b.price;

  let aPrice = a.price
    ? a.price
    : a.options.length
    ? a.options.sort((a, b) => a.price - b.price)[0].price
    : 0;
  let bPrice = b.price
    ? b.price
    : b.options.length
    ? b.options.sort((a, b) => a.price - b.price)[0].price
    : 0;

  return aPrice - bPrice;
};

const SubCategory = ({ subCategory }) => {
  const { state, menuDispatch, menu, filteredMeals: meals } = useContext(
    ApiContext
  );
  const { language } = useContext(LanguageContext);

  const filteredMeals = useMemo(() => {
    if (!meals) return [];
    // @TODO && m.showOnMen

    return meals
      .filter((m) => m.subCategory == subCategory.id)
      .sort(sortByPrice)
      .sort(sortByTag);
  }, [meals, subCategory.id]);

  const { title = "" } = useTranslations(subCategory.translations);

  return (
    <div
      style={{ paddingBottom: 50 }}
      id={"cat-" + subCategory.id}
      style={{ display: !filteredMeals.length ? "none" : null }}
      key={subCategory.id}
    >
      {subCategory && (
        <SubCategoryImage img={subCategory?.image} title={title} />
      )}
      {filteredMeals.map((meal) => (
        <MealItem language={language} meal={meal} />
      ))}
    </div>
  );
};

export default SubCategory;
