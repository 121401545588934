import { FormControl, InputLabel, Select } from "@material-ui/core";
import React from "react";
import { translationsHelper } from "../../../../CustomHooks/useTranslations";

const Option = ({ id, translations, language = "de", price = 0 }) => {
  const title = translationsHelper(translations, language, "self");

  return (
    <option key={id} value={id}>
      {title}{" "}
      {price && typeof price === "number"
        ? `(+${price.toFixed(2).replace(".", ",")} €)`
        : "(Kostenlos)"}
    </option>
  );
};

const SingleSelect = ({
  name,
  value,
  options = [],
  onChange,
  required = true,
  language = "de",
  translations = {},
}) => {
  if (required && !value && options.length) {
    onChange({ target: { name, value: options[0].id } });
  }

  const { title = "", description = "" } = translationsHelper(
    translations,
    language
  );

  return (
    <FormControl style={{ width: "100%", marginBottom: 10 }}>
      <InputLabel>
        {title} {required ? "(Pflichtfeld)" : ""}
      </InputLabel>
      <Select
        fullWidth
        native
        fullWidth
        required={required}
        value={value}
        onChange={onChange}
        inputProps={{
          name,
        }}
      >
        {!required && <option aria-label="None" value="" />}
        {options.map((option) => (
          <Option key={option.id} {...option} language={language} />
        ))}
      </Select>
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description.replace(
              /(<p><i><\/i><\/p>)|(<p><\/p>)/gm,
              "<br/>"
            ),
          }}
          style={{ opacity: 0.7, fontSize: "70%" }}
        />
      )}
    </FormControl>
  );
};

export default SingleSelect;
