import { useContext, useState } from 'react';
import RegularMenu from './RegularMenu';
import { CSSTransition } from 'react-transition-group';
import { ApiContext } from '../../Contexts/ApiContext';
import Settings from '../Settings';

const Footer = () => {
  const [footerHeight, setfooterHeight] = useState(58);
  const { menu } = useContext(ApiContext);

  const calcHeight = el => {
    const height = el.offsetHeight;
    setfooterHeight(height + 10);
  };

  return (
    <div className="footerContainer">
      <div className="footer" style={{ height: footerHeight }}>
        <CSSTransition
          in={menu.current === 'settings'}
          timeout={500}
          unmountOnExit
          classNames="footer-settings"
          onEnter={calcHeight}
        >
          <Settings />
        </CSSTransition>

        <CSSTransition
          in={menu.current === 'regular'}
          timeout={500}
          unmountOnExit
          classNames="footer-regular"
          onEnter={calcHeight}
        >
          <RegularMenu />
        </CSSTransition>
      </div>
    </div>
  );
};

export default Footer;
