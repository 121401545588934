import { useContext, useMemo } from "react";
import { ApiContext } from "../../../Contexts/ApiContext";
import { Typography, Divider } from "@material-ui/core";
import { ReactComponent as Vegan } from "./Icons/Vegan.svg";
import { ReactComponent as Veggie } from "./Icons/Veggie.svg";
import Icon from "./Icon";
import AddToCart from "./AddToCart";
import Allergens from "./Allergens";
import MealItemOption from "./MealItemOption";
import { stringifyPrice } from "../../../helperFunctions";
import FoodPairings from "./FoodPairings";
import { SearchRounded } from "@material-ui/icons";
import useTranslations from "../../../CustomHooks/useTranslations";

const texts = {
  de: {
    vegan: "Vegan",
    veggie: "Vegetarisch",
    attention:
      "Dieses Gericht könnte bei Ihnen allergische Reaktionen auslösen",
    unavailable: "Leider aktuell nicht verfügbar",
  },
  en: {
    vegan: "Vegan",
    veggie: "Vegetarian",
    attention: "You may be allergic to this dish",
    unavailable: "Sadly currently not available",
  },
};

const MealItem = ({ type = "standard", language = "de", meal = {} }) => {
  const {
    title = "",
    tags = "",
    description = "",
  } = useTranslations(meal.translations);

  const {
    state: {
      unavailable = [],
      restaurant: { colorPalette = {}, showImageOnMeal = true },
    },
    openImageModal,
    filteredMeals = [],
  } = useContext(ApiContext);

  const available = useMemo(
    () => !unavailable.includes(meal.id),
    [unavailable, meal.id]
  );

  const foodPairings = useMemo(() => {
    if (!meal.food_pairing || !meal.food_pairing.length) {
      return [];
    }

    return meal.food_pairing
      .filter((mId) => !unavailable.includes(mId))
      .map((id) => filteredMeals.find((m) => m.id === id));
  }, [meal.food_pairing]);

  const translatedTexts = texts[language] ?? texts.de;

  return (
    <div className="mealContainer" style={{ opacity: available ? 1 : 0.5 }}>
      {showImageOnMeal && meal.image && (
        <div
          onClick={() => openImageModal(meal.image)}
          className="mealContainerImage"
          style={{
            backgroundImage: `url("${meal.image}")`,
            position: "relative",
            cursor: "pointer",
          }}
        >
          <SearchRounded
            size="small"
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              color: "white",
            }}
          />
        </div>
      )}
      <div className="mealContainerInner">
        {tags && (
          <span
            className="mealTag"
            style={{ backgroundColor: colorPalette.primaryColor }}
          >
            {tags}
          </span>
        )}
        <div className="mealHeader">
          <Typography variant="h3">{title}</Typography>
          <div className="mealContainerInnerAllergens">
            <Allergens allergens={meal.allergens} />
            {meal.vegan && (
              <Icon icon={<Vegan />} tooltip={translatedTexts.vegan} />
            )}
            {meal.vegetarian && !meal.vegan && (
              <Icon icon={<Veggie />} tooltip={translatedTexts.veggie} />
            )}
          </div>
          <Typography variant="h3" style={{ flexGrow: 1, textAlign: "right" }}>
            {!!meal?.optionsV02?.length && "ab "}
            {stringifyPrice(meal.price, "€")}
          </Typography>
        </div>
        {available && (
          <Typography
            className="mealDescription"
            dangerouslySetInnerHTML={{
              __html: description?.replace(
                /(<p><i><\/i><\/p>)|(<p><\/p>)/gm,
                "<br/>"
              ),
            }}
          />
        )}
        {/* {meal.options.map(option => (
          <MealItemOption option={option} language={language} />
        ))} */}
        {available && (
          <AddToCart
            id={meal.id}
            language={language}
            hasOptions={!!meal.optionsV02?.length}
          />
        )}

        {!!foodPairings.length && <FoodPairings foodPairings={foodPairings} />}
      </div>
    </div>
  );
};

export default MealItem;
