import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import moment from "moment";
import {
  TextField,
  Box,
  MenuItem,
  Typography,
  useForkRef,
} from "@material-ui/core";
import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import {
  toTimeArr,
  dateHelper,
  timeHelper,
  timeToBlock,
  blockToTime,
} from "../../../helperFunctions";
import { server, ApiContext } from "../../../Contexts/ApiContext";

const toTimezone = (string) => {
  const d = new Date(`${string}z`);

  return d.setTime(d.getTime() - d.getTimezoneOffset());
};

const timeArrFromStartAndClose = (
  start,
  close,
  date,
  time,
  emergencyClosed,
  longestWaitingTime = 1
) => {
  let times = [];
  let today = date === dateHelper();

  // console.log({
  //   start,
  //   close,
  //   date,
  //   time,
  //   emergencyClosed,
  //   longestWaitingTime,
  // });

  const startBlock = !today
    ? timeToBlock(start)
    : Math.max(
        timeToBlock(start),
        timeToBlock(timeHelper()) + longestWaitingTime + 1
  );

  let closeBlock = timeToBlock(close);

  if (closeBlock < timeToBlock(start)) {
    closeBlock += 96;
  }

  for (let currentTime = startBlock; currentTime <= closeBlock; currentTime++) {
    if (today) {
      if (
        emergencyClosed?.active &&
        (!emergencyClosed.deliveryOptions ||
          (emergencyClosed.deliveryOptions.includes("pickup") &&
            emergencyClosed.deliveryOptions.includes("delivery")))
      ) {
        if (
          currentTime > time &&
          !!emergencyClosed.until &&
          emergencyClosed.until <=
            toTimezone(`${date}T${blockToTime(currentTime)}`)
        ) {
          times.push(currentTime);
        }
      } else if (currentTime > time) {
        times.push(currentTime);
      }
    } else {
      times.push(currentTime);
    }
  }

  return times;
};

const translations = {
  de: {
    hasNoTime: "keine Uhrzeit gewählt",
    label: "Datum & Uhrzeit",
    timeLabel: "Uhrzeit",
  },
  en: {
    hasNoTime: "No Time was choosen",
    label: "Date & Time",
    timeLabel: "Time",
  },
};

const mobile = false;

const today = moment().format("YYYY-MM-DD");

const DateAndTime = ({
  onChange,
  emergencyClosed = null,
  todaysOpeningHours = [],
  shifts = [],
  specialDays = [],
  value = "",
  language = "de",
  shiftVersion = "v02",
  restaurantId,
}) => {
  const [dateActive, setdateActive] = useState(false);

  if (value === null) value = "";

  let [date = null, time = ""] =
    value && value.startsWith("20") ? value.split("T") : [];

  useEffect(() => {
    onChange(
      state.date + "T" + state.time,
      dateActive?.shifts?.find(
        (s) =>
          s.start <= value &&
          s.close >= value &&
          !s.closed &&
          !s.disabled &&
          s.active
      )
    );
  }, [dateActive.shifts]);

  const [state, setstate] = useState({
    date,
    time,
  });

  const [shiftsOnDay, setShiftsOnDay] = useState({
    date: dateHelper(),
    shifts: todaysOpeningHours,
    loading: false,
  });

  const {
    state: { restaurant = {} },
    calendar,
  } = useContext(ApiContext);

  const texts = translations[language] ?? translations.de;

  const handleMobileDateChange = useCallback(
    (e) => {
      let { value: date } = e.target;
      setstate((st) => ({ ...st, date }));
    },
    [setstate]
  );

  useEffect(() => {
    onChange(
      state.date + "T" + state.time,
      dateActive?.shifts?.find(
        (s) =>
          s.start <= value &&
          s.close >= value &&
          !s.closed &&
          !s.disabled &&
          s.active
      )
    );
    if (state.date && !state.time) {
      setstate((st) => ({ ...st, error: "hasNoTime" }));
    } else {
      setstate((st) => ({ ...st, error: null }));
    }
  }, [dateActive, calendar]);

  const isDayBlocked = useCallback(
    (date) => {
      if (date.format("YYYY-MM-DD") < today) {
        return true;
      }

      console.log({ date, shifts, d: date.format("YYYY-MM-DD"), specialDays, shiftVersion, calendar });

      if (
        date.format("YYYY-MM-DD") === today &&
        emergencyClosed?.active &&
        !emergencyClosed.until 
      ) {
        return true;
      }

      if (calendar && calendar?.[date.format("YYYY-MM-DD")]) {
        return calendar?.[date.format("YYYY-MM-DD")]?.some((s) => s.closed);
      }

      let wDay = new Date(date.format("YYYY-MM-DD")).getDay();

      let specialDay = specialDays?.find((s) =>
        s.dates.includes(date.format("YYYY-MM-DD"))
      );

      // console.log(specialDay, date.format("YYYY-MM-DD"));

      if (specialDay && specialDay.closed) {
        return true;
      }

      return (
        !specialDay &&
        shiftVersion === "v01" &&
        !shifts.find((s) => s.wDays.includes(wDay))
      );
    },
    [shifts, specialDays, today, calendar, shifts]
  );

  const handleDateChange = useCallback(
    (date) => {
      if (isDayBlocked(date)) return;
      setstate((st) => ({
        date: moment(date).format("YYYY-MM-DD"),
        time: "",
      }));
    },
    [setstate, isDayBlocked]
  );

  const handleTimeChange = useCallback(
    ({ target: { value } }) => {
      setstate((st) => ({
        ...st,
        time: value,
      }));
      setdateActive(false);
    },
    [setstate]
  );

  const handleDateFocus = useCallback(
    (e) => {
      if (!mobile) {
        let target = e.target.getBoundingClientRect();
        setdateActive(target);
      } else {
        setdateActive(true);
      }
    },
    [setdateActive]
  );

  const handleDateBlur = useCallback(
    (e) => {
      if (dateActive.hovered) {
        return;
      }
      setdateActive(false);
    },
    [dateActive, setdateActive]
  );

  const loadShiftsOnDay = async (date) => {
    // console.log({ shifts: calendar?.[date], date})
    if (calendar?.[date]?.length) {
      setShiftsOnDay({
        shifts: calendar[date] || [],
        date,
      });

      return;
    } else {
      setShiftsOnDay((s) => ({
        ...s,
        shifts: [],
        loading: true,
      }));
    }

    try {

      const { data } = await server.get(
        `v03/takeAway/${restaurantId}/getDate/${date}`
      );

      setShiftsOnDay({
        shifts: data.shifts || [],
        date,
      });
    } catch (error) {
      setShiftsOnDay({
        shifts: [],
        date,
      });
    }
  };

  useEffect(() => {
    if (shiftVersion.toLocaleLowerCase() === "v02") {
      let date = state.date ? state.date : value.split("T")[0];

      // console.log({ date, xxx: true });

      if (!date || date === dateHelper()) {
        setShiftsOnDay({ date: dateHelper(), shifts: todaysOpeningHours });
      } else if (date !== shiftsOnDay.date) {
        loadShiftsOnDay(date);
      }
    }
  }, [state.date, value, calendar]);

  const currentWaitingTimes = restaurant?.currentWaitingTimes ?? {
    delivery: "00:30",
    pickup: "00:15",
  };

  const availableTimes = useMemo(() => {
    let date = state.date ? state.date : value.split("T")[0];
    
    // console.log({ shiftsOnDay, shiftVersion })

    if (
      shiftVersion.toLocaleLowerCase() === "v02" &&
      (date !== shiftsOnDay.date || (!shiftsOnDay.shifts.length && !!calendar?.[date]?.length && !shiftsOnDay.loading))
    ) {
      if (!shiftsOnDay.loading) {
        loadShiftsOnDay(date);
      }

      return [];
    }

    let wDay = new Date(`${date}T00:00Z`).getDay();

    let sDays = specialDays?.filter((s) => s.dates.includes(date));

    let times = [];

    let time = timeToBlock(timeHelper());

    let filteredShifts =
      shiftVersion.toLocaleLowerCase() === "v02"
        ? shiftsOnDay.shifts.filter((s) => !s.isClosed)
        : shifts.filter((s) => s.wDays.includes(wDay));

    // console.log({ emergencyClosed, filteredShifts });

    // console.log({ filteredShifts, currentWaitingTimes, shiftsOnDay, date });

    filteredShifts.forEach((s) => {
      times.push(
        ...timeArrFromStartAndClose(
          s.start,
          s.close,
          date,
          time,
          emergencyClosed,
          Math.max(
            ...(s.deliveryMethods ?? ["pickup"]).map((s) =>
              timeToBlock(
                currentWaitingTimes[s] ?? currentWaitingTimes.pickup ?? "00:30"
              )
            )
          )
        )
      );
      // console.log(s.start,
      //   s.close,
      //   date,
      //   time,
      //   emergencyClosed,
      //   Math.max(
      //     ...(s.deliveryMethods ?? ["pickup"]).map((s) =>
      //       timeToBlock(
      //         currentWaitingTimes[s] ?? currentWaitingTimes.pickup ?? "00:30"
      //       )
      //     )
      //   ),
      //   times
      // )
    });

    if (shiftVersion.toLocaleLowerCase() !== "v02") {
      sDays.forEach((s) => {
        if (s.overwrite) times = [];

        times.push(
          ...timeArrFromStartAndClose(
            s.start,
            s.close,
            date,
            time,
            emergencyClosed,
            Math.max(
              ...(s.deliveryMethods ?? ["pickup"]).map((s) =>
                timeToBlock(
                  currentWaitingTimes[s] ??
                    currentWaitingTimes.pickup ??
                    "00:30"
                )
              )
            )
          )
        );
      });
    } else {
      let closedTimes = [];

      shiftsOnDay.shifts
        .filter((s) => !!s.isClosed)
        .forEach((s) => {
          closedTimes.push(
            ...timeArrFromStartAndClose(
              s.start,
              s.close,
              date,
              time,
              emergencyClosed,
              s.isClosed
            )
          );
        });

      if (closedTimes.length) {
        times = times.filter((t) => !closedTimes.includes(t));
      }
    }

    return Array.from(new Set(times))
      .sort((a, b) => a - b)
      .map((x) => blockToTime(x));
  }, [value, state.date, shiftsOnDay, calendar]);

  const deliveryOptions = Array.from(
    new Set(
      shiftsOnDay?.shifts?.reduce(
        (acc, cV) => [...acc, ...(cV?.deliveryMethods ?? [])],
        []
      ) ?? []
    )
  );

  // console.log(deliveryOptions);

  /*
  const availableTimes = useMemo(() => {
    let date = state.date ? state.date : value.split("T")[0];

    let wDay = new Date(date).getDay();

    let sDays = specialDays.filter((s) => s.dates.includes(date));

    let shift = shifts
      .filter((s) => s.wDays.includes(wDay))
      .reduce(
        (acc, cV) => {
          if (!acc.start || cV.start < acc.start) {
            acc.start = cV.start;
          }

          if (!acc.close || cV.close > acc.close) {
            acc.close = cV.close;
          }

          return acc;
        },
        {
          start: null,
          close: null,
        }
      );

    if (!shift.start) {
      shift = null;
    }

    if (!shift && (!sDays.length || sDays.every((s) => s.closed))) return [];

    // let start = shift && specialDay .start ;

    let timeArr = [];

    const calculateStartClose = (start, close) => {
      if (dateHelper() === date) {
        start = start > timeHelper(4) ? start : timeHelper(4);
      }
      return [start, close];
    };

    if (sDays && (sDays.some((s) => s.overwrite) || !shift)) {
      let times = [];

      sDays.forEach((s) => {
        times.push(...toTimeArr(...calculateStartClose(s.start, s.close)));
      });

      timeArr.push(...new Set(times));
    } else if (!sDays.length && shift) {
      timeArr = toTimeArr(...calculateStartClose(shift.start, shift.close));
    } else {
      let times = toTimeArr(...calculateStartClose(shift.start, shift.close));

      sDays.forEach((s) => {
        times.push(...toTimeArr(...calculateStartClose(s.start, s.close)));
      });

      timeArr.push(...new Set(times));
    }

    return [...new Set(timeArr)].sort();
  }, [value, state.date]);

  */

  return (
    <>
      <TextField
        id="date"
        name="date"
        label={texts.label}
        variant="outlined"
        fullWidth
        size="small"
        style={{ marginBottom: 10 }}
        onChange={mobile ? handleMobileDateChange : () => {}}
        value={
          mobile
            ? state.date
            : state.date
            ? `${moment(state.date).format("DD.MM.YYYY")} ${state.time}`
            : null
        }
        onFocus={handleDateFocus}
        onBlur={mobile ? handleMobileDateChange : handleDateBlur}
        error={state.error}
        helperText={state.error && texts[state.error]}
        InputLabelProps={{
          shrink: !!state.date || dateActive,
        }}
        InputProps={{
          type: mobile ? "date" : null,
        }}
      />
      {!!deliveryOptions.length && state.date === today && (
        <Typography
          variant="caption"
          style={{ lineHeight: "120%", marginBottom: 8 }}
        >
          Mindest Wartezeit:
          <br />
          <small>
            Es besteht immer eine Mindest Wartezeit ab Bestellung, auch wenn
            früher Zeiten verfügbar sind
          </small>{" "}
          {deliveryOptions.includes("pickup") && (
            <>
              <br />
              Abholung: {currentWaitingTimes.pickup}h
            </>
          )}
          {deliveryOptions.includes("delivery") && (
            <>
              <br />
              Lieferung: {currentWaitingTimes.delivery}h
            </>
          )}
        </Typography>
      )}
      {dateActive && !mobile && (
        <Box className="DayPickerContainer">
          <div
            onMouseEnter={() => setdateActive({ ...dateActive, hovered: true })}
            onMouseLeave={() =>
              setdateActive({ ...dateActive, hovered: false })
            }
          >
            <DayPickerSingleDateController
              numberOfMonths={1}
              date={state.date ? moment(state.date) : null} // momentPropTypes.momentObj or null,
              dateId="date" // PropTypes.string.isRequired,
              onDateChange={handleDateChange} // PropTypes.func.isRequired,
              isDayHighlighted={isDayBlocked}
              focusedInput={"date"} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              //onFocusChange={fI => setdateActive(false)} // PropTypes.func.isRequired,
              noBorder
            />
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <TextField
                fullWidth
                value={state.time}
                label={texts.timeLabel}
                disabled={!state.date}
                name="time"
                onChange={handleTimeChange}
                select
                size="small"
                onFocus={() => setdateActive({ ...dateActive, hovered: true })}
                onBlur={() => setdateActive(false)}
                variant="outlined"
              >
                {shiftsOnDay?.loading && (
                  <MenuItem value="">Loading...</MenuItem>
                )}
                {!!shiftsOnDay &&
                  !shiftsOnDay.loading &&
                  !availableTimes.length && (
                    <MenuItem value="">Keine Verfügbaren Zeiten</MenuItem>
                  )}
                {availableTimes.map((t) => (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default DateAndTime;
