import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const translations = {
  de: {
    orderMethod: 'Liefermethode',
    delivery: 'Lieferung',
    pickup: 'Selbstabholung',
    orderTime: 'Lieferzeit',
    now: 'So schnell wie möglich',
    wDay: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ]
  },
  en: {
    orderMethod: 'Liefermethode',
    delivery: 'Lieferung',
    pickup: 'Selbstabholung',
    orderTime: 'Lieferzeit',
    now: 'So schnell wie möglich',
    wDay: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ]
  }
};

const stringifyOrderTime = (orderTime, wDays) => {
  if (!orderTime) return `Fehler`;
  let [date, time] = orderTime.split('T');

  let [year, month, day] = date.split('-');

  let wDay = new Date(date).getDay();

  return `${wDays[wDay]}, ${day}.${month} ${time}`;
};

const OrderMethodAndTime = ({ orderMethod, orderTime, language = 'de' }) => {
  const texts = translations[language];

  console.log(orderTime);

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={4}>
        <Typography>
          <strong>{texts.orderMethod}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>{texts[orderMethod]}</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography>
          <strong>{texts.orderTime}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>
          {orderTime === 'now' || orderTime === 'nullT'
            ? texts.now
            : stringifyOrderTime(orderTime, texts.wDay)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderMethodAndTime;
