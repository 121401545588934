import { useContext, useMemo } from "react";
import { ApiContext } from "../../../Contexts/ApiContext";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { ReactComponent as Attention } from "./Icons/Attention.svg";
import { ReactComponent as Achtung } from "./Icons/Achtung.svg";
import Icon from "./Icon";

const texts = {
  de: {
    attention:
      "Dieses Gericht könnte bei Ihnen allergische Reaktionen auslösen",
  },
  en: {
    attention: "You may be allergic to this dish",
  },
};

const Allergens = ({ allergens = [], language = "de" }) => {
  const { menu, openAllergenModal } = useContext(ApiContext);

  const AttentionIcon = language === "de" ? <Achtung /> : <Attention />;

  const warningNeeded = useMemo(() => {
    if (!menu.allergens) return false;

    return allergens.some((a) => menu.allergens.includes(a));
  }, [menu.allergens, allergens]);

  return (
    <div
      style={{
        display: "inline-flex",
        marginLeft: 5,
        fontSize: "11px",
        fontStyle: "italic",
        alignItems: "flex-end",
      }}
      onClick={openAllergenModal(allergens)}
    >
      {allergens
        .sort((a, b) => (parseInt(a) || a) - (parseInt(b) || b))
        .map((a, i) => (
          <span key={a}>
            {a}
            {i !== allergens.length - 1 && ","}{" "}
          </span>
        ))}
      {warningNeeded && (
        <Icon icon={AttentionIcon} tooltip={texts[language].attention} />
      )}
    </div>
  );
};

export default Allergens;
