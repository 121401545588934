const timeToBlock = (time) => {
  if (typeof time === "number") {
    return time;
  }

  if (!time) {
    return NaN;
  }

  if (Math.ceil(parseInt(time.split(":")[1])) > 60) {
    return NaN;
  }

  if (parseInt(time.split(":")[0]) > 24) {
    return NaN;
  }

  return (
    parseInt(time.split(":")[0]) * 4 +
    Math.ceil(parseInt(time.split(":")[1]) / 15)
  );
};

const blockToTime = (block) => {
  var h = Math.floor(block / 4);
  var m = (block % 4) * 15;
  if (m < 10) {
    m = "0" + m;
  }
  if (h >= 24) {
    h -= 24;
  }
  if (h < 10) {
    h = "0" + h;
  }
  return h + ":" + m;
};

const toTimeArr = (start, close) => {
  let s = timeToBlock(start);
  let c = timeToBlock(close) + 1;

  console.log(s, c);

  if (s >= c) {
    return [];
  }

  return Array.from(Array(c - s), (x, b) => blockToTime(b + s));
};

const dateHelper = (date = new Date()) => {
  return date.toISOString().split("T")[0];
};

const timeHelper = (addBlocks = 0) =>
  blockToTime(timeToBlock(new Date().toTimeString().split(" ")[0]) + addBlocks);

const stringifyPrice = (price = 0, currency = "") => {
  return currency + price.toFixed(2);
};

module.exports = {
  timeToBlock,
  blockToTime,
  toTimeArr,
  dateHelper,
  timeHelper,
  stringifyPrice,
};
