import { useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  Typography,
  Divider,
  Button,
  Chip,
  IconButton,
} from "@material-ui/core";
import { ApiContext } from "../../../Contexts/ApiContext";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { ReactComponent as EmptyCartIcon } from "./Icons/EmptyCart.svg";
import { stringifyPrice } from "../../../helperFunctions";
import CartItem from "./CartItem";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@material-ui/icons";
const translations = {
  de: {
    title: "Warenkorb",
    subtotal: "Zwischensumme",
    minOrder: "Der Mindest-Bestellwert beträgt",
    deliveryFee: "Liefergebühr",
    total: "Gesamtbetrag",
    vatDisclaimer:
      "Alle Preise verstehen sich inklusive gesetzlicher Mehrwertsteuer",
    order: "Bestellen",
    serviceFee: "Service Gebühr",
    tip: "Trinkgeld",
    remove: "entfernen",
    voucher: "Gutschein",
    coupon: "Rabatt",
  },
  en: {
    title: "Shopping Cart",
    subtotal: "Subtotal",
    minOrder: "The minimal order value is",
    deliveryFee: "Delivery fee",
    total: "Total",
    vatDisclaimer: "All prices are inclusive of VAT",
    order: "Order Now",
    serviceFee: "Service Fee",
    tip: "Tip",
    remove: "remove",
    voucher: "Voucher",
    coupon: "Coupon",
  },
};

const EmptyCart = ({ language }) => {
  return (
    <div className="empty-cart">
      <EmptyCartIcon />
      <span>
        {language === "de" ? "Der Warenkorb ist leer" : "Your cart is empty"}
      </span>
    </div>
  );
};

const ShoppingCart = ({ history, page = "main", mobile }) => {
  const {
    state: { restaurant = {} },
    menu,
    calculations: {
      subTotal,
      total,
      tip,
      serviceFee,
      deliveryFee,
      coupon,
      voucher,
    },
    restaurantId,
    menuDispatch,
    setcartOpen,
    minOrderValue = 0,
  } = useContext(ApiContext);

  let { cart = [], orderMethod = null } = menu;

  const { language } = useContext(LanguageContext);

  const texts = translations?.[language] ?? translations.de;

  const handleSubmit = () => history.push(`/${restaurantId}/info`);

  const neoSpecialDay =
    restaurantId === "neo-heidelberg" &&
    (menu?.orderTime?.includes("2021-04-13") ||
      new Date().toISOString().startsWith("2021-04-13"));

  return (
    <>
      <div className="cart-header">
        {mobile && (
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackOutlined />
          </IconButton>
        )}
        <span>{texts?.title}</span>
        {!mobile && (
          <IconButton
            size="small"
            onClick={() => setcartOpen(false)}
            className="close"
          >
            <ArrowForwardOutlined size="small" />
          </IconButton>
        )}
      </div>
      <aside>
        <div
          style={{
            maxHeight: "40vh",
            overflow: "scroll",
          }}
        >
          {!cart.length && <EmptyCart language={language} />}
          {!!cart.length &&
            cart.map((cartItem) => (
              <CartItem
                key={cartItem.id}
                data={cartItem}
                editable={page !== "payment"}
              />
            ))}
        </div>
        <Divider />
        <div className="cart-sum-container">
          <div>
            <Typography>{texts.subtotal}</Typography>
            <Typography>{stringifyPrice(subTotal, "€")}</Typography>
          </div>

          {!!voucher && (
            <>
              <Divider style={{ marginBottom: 10 }} />
              <div>
                <Typography>
                  {texts.voucher}
                  {page !== "payment" && (
                    <Chip
                      label={texts.remove}
                      variant="outlined"
                      size="small"
                      onDelete={() => menuDispatch({ type: "removeVoucher" })}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </Typography>
                <Typography>{stringifyPrice(-1 * voucher, "€")}</Typography>
              </div>
            </>
          )}

          {!!coupon && (
            <>
              <Divider style={{ marginBottom: 10 }} />
              <div>
                <Typography>
                  {texts.coupon}
                  {page !== "payment" && (
                    <Chip
                      label={texts.remove}
                      variant="outlined"
                      size="small"
                      onDelete={() => menuDispatch({ type: "removeCoupon" })}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </Typography>
                <Typography>{stringifyPrice(-1 * coupon, "€")}</Typography>
              </div>
            </>
          )}

          {false && (
            <>
              <Divider style={{ marginBottom: 10 }} />
              <div>
                <Typography>{texts.serviceFee}</Typography>
                <Typography>{stringifyPrice(serviceFee, "€")}</Typography>
              </div>
            </>
          )}
          {!!deliveryFee && (
            <div>
              <Typography>{texts.deliveryFee}</Typography>
              <Typography>{stringifyPrice(deliveryFee, "€")}</Typography>
            </div>
          )}
          {!!tip && (
            <>
              <Divider style={{ marginBottom: 10 }} />
              <div>
                <Typography>
                  {neoSpecialDay ? "Zusätzliche Spende" : texts.tip}
                  {page !== "payment" && (
                    <Chip
                      label={texts.remove}
                      variant="outlined"
                      size="small"
                      onDelete={() => menuDispatch({ type: "tipRemoved" })}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </Typography>
                <Typography>{stringifyPrice(tip, "€")}</Typography>
              </div>
            </>
          )}

          <div>
            <Typography style={{ fontWeight: "bold" }}>
              {texts.total}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              {stringifyPrice(total, "€")}
            </Typography>
          </div>
          <small>{texts.vatDisclaimer}</small>
        </div>

        {subTotal < minOrderValue && (
          <Typography style={{ textAlign: "center" }}>
            {texts.minOrder} {stringifyPrice(minOrderValue, "€")}
          </Typography>
        )}

        {page === "main" && (
          <Button
            variant="contained"
            color="primary"
            disabled={
              (minOrderValue && subTotal < minOrderValue) || !cart.length
            }
            onClick={handleSubmit}
            fullWidth
          >
            {texts.order}
          </Button>
        )}
      </aside>
    </>
  );
};

export default withRouter(ShoppingCart);
