import { useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { ApiContext } from "../../../Contexts/ApiContext";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import SettingsButton from "./SettingsButton";
import { Badge, Icon, IconButton } from "@material-ui/core";
import { ArrowBackRounded, ShoppingCart } from "@material-ui/icons";
import { translationsHelper } from "../../../CustomHooks/useTranslations";
let tempCat = null;

const CategoryList = () => {
  const { language } = useContext(LanguageContext);
  const scrollRef = useRef(null);
  const {
    filteredCategories,
    setcurrentCategory,
    currentCategory,
    search = null,
    setsearch,
    cartOpen,
    setcartOpen,
    cartItems,
    menu: { oldOrderTime },
    goBack,
  } = useContext(ApiContext);

  useEffect(() => {
    console.log(tempCat);
    if (!tempCat || tempCat === currentCategory) {
      if (currentCategory && scrollRef.current) {
        let el = document.getElementById("cat-list-" + currentCategory);

        if (el) {
          scrollRef.current.scrollLeft = el.offsetLeft - 50;
        }
      }
    }
  }, [currentCategory, tempCat]);

  const handleCategoryClick = useCallback(
    (id) => () => {
      setcurrentCategory(id);
      let el = document.getElementById("cat-" + id);
      let main =
        window.innerWidth < 760 ? null : document.getElementById("main");

      if (el && main) {
        tempCat = id;
        main.scrollTop = el.offsetTop - 150;
        setTimeout(() => {
          tempCat = null;
        }, 1000);
      } else {
        tempCat = id;
        window.scrollTo({
          top: el.offsetTop - 60,
          left: 0,
          behavior: "auto",
        });
        setTimeout(() => {
          tempCat = null;
        }, 1000);
      }
    },
    [setcurrentCategory]
  );

  // const filteredAds = useMemo(() => {
  //   if(!ads || !ads.length) return [];

  //   return ads.filter(a => !filteredCategories.find(fC => fC.id === a.id))

  // }, [ads, filteredCategories]);

  return (
    <div className="category-list">
      {oldOrderTime && (
        <div
          className={search === null ? "search" : "search active"}
          onClick={goBack}
        >
          <ArrowBackRounded />
        </div>
      )}
      <div className="category-list-scroll" ref={scrollRef}>
        {filteredCategories.map((cat) => (
          <span
            key={cat.id}
            id={"cat-list-" + cat.id}
            onClick={handleCategoryClick(cat.id)}
            className={
              cat.id === currentCategory
                ? "category-list-item active"
                : "category-list-item"
            }
          >
            {translationsHelper(cat.translations, language)?.title}
          </span>
        ))}
      </div>
      <div className="settings">
        <SettingsButton />
      </div>
      {!cartOpen && (
        <div className="cartToggle">
          <IconButton size="small" onClick={() => setcartOpen(true)}>
            <Badge badgeContent={cartItems} color="primary">
              <ShoppingCart size="small" />
            </Badge>
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
