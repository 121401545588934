import { useEffect, useRef } from 'react'

const useMetaTexts = ({ title = '', description = '', jsonLd = null }) => {
    const ref = useRef(null);

    useEffect(() => {
        
        if(title) {

            let head = document.getElementsByTagName('head')[0];

            head.getElementsByTagName('title')[0].innerText = title;
            head.getElementsByTagName('meta')[0].setAttribute('content', description);

            if(jsonLd) {
                let script = document.createElement('script');
                script.setAttribute('type', 'application/ld+json');
                script.innerHTML = `${jsonLd}`;
                head.append(script);
            }
        }
    }, [title])

    return;
}

export default useMetaTexts
