import { useContext } from 'react';
import { Typography, Switch } from '@material-ui/core';
import { LanguageContext } from '../../Contexts/LanguageContext';
import { ApiContext } from '../../Contexts/ApiContext';
import { ReactComponent as Vegan } from './Icons/Vegan.svg';
import { ReactComponent as Veggie } from './Icons/Veggie.svg';
import { ReactComponent as NoAlc } from './Icons/NoAlc.svg';

const PreferenceElement = ({
  id = '',
  iconLeft = null,
  title = '',
  value = true,
  onChange = () => {}
}) => {
  return (
    <div key={id} className="preferenceElement">
      {iconLeft}
      <Typography>{title}</Typography>
      <Switch checked={value} onChange={onChange} name={id} color="primary" />
    </div>
  );
};

const Preferences = () => {
  const { texts } = useContext(LanguageContext);
  const { menu, menuDispatch } = useContext(ApiContext);

  const handleChange = ({ target: { checked, name } }) => {
    let type = checked ? 'addPreference' : 'removePreference';

    menuDispatch({ type, payload: name });
  };

  return (
    <div>
      <PreferenceElement
        id="vegan"
        title={texts.vegan}
        iconLeft={<Vegan />}
        value={menu.preferences.includes('vegan')}
        onChange={handleChange}
      />
      <PreferenceElement
        id="veggie"
        title={texts.veggie}
        iconLeft={<Veggie />}
        value={menu.preferences.includes('veggie')}
        onChange={handleChange}
      />
      <PreferenceElement
        id="noAlc"
        title={texts.noAlc}
        iconLeft={<NoAlc />}
        value={menu.preferences.includes('noAlc')}
        onChange={handleChange}
      />
    </div>
  );
};

export default Preferences;
