import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { translationsHelper } from "../../../../CustomHooks/useTranslations";

const SHOW_LESS = 6;

const Option = ({
  id,
  translations,
  language = "de",
  price = 0,
  checked = false,
  onChange,
}) => {
  const title = translationsHelper(translations, language, "self");

  return (
    <FormControlLabel
      key={id}
      control={<Checkbox checked={checked} onChange={onChange} name={id} />}
      label={`${title} ${
        price && typeof price === "number"
          ? `(+${price.toFixed(2).replace(".", ",")} €)`
          : "(Kostenlos)"
      }`}
    />
  );
};

const MultipleSelect = ({
  name,
  value = [],
  options = [],
  onChange,
  maxAmount,
  language = "de",
  translations = {},
}) => {
  const [showMore, setShowMore] = useState(false);

  const { title = "", description = "" } = translationsHelper(
    translations,
    language,
    "title"
  );

  console.log(title, translations);

  const handleChange = (id) => {
    let checked = value?.includes(id);

    if (!checked && maxAmount && value?.length >= maxAmount) {
      alert(`Maximal ${maxAmount}`);
      return;
    }

    let val = checked ? value.filter((v) => v !== id) : [...value, id];

    onChange({ target: { name, value: val } });
  };

  const filteredOptions = useMemo(() => {
    if (showMore) return options;

    return options.slice(0, SHOW_LESS);
  }, [options, showMore]);

  return (
    <FormControl style={{ width: "100%", marginBottom: 10 }}>
      <FormLabel style={{ fontSize: "0.8rem" }} component="legend">
        {title} {!!maxAmount && `(max. ${maxAmount})`}
      </FormLabel>
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description.replace(
              /(<p><i><\/i><\/p>)|(<p><\/p>)/gm,
              "<br/>"
            ),
          }}
          style={{ opacity: 0.7, fontSize: "70%" }}
        />
      )}
      <FormGroup>
        {filteredOptions.map((option) => (
          <Option
            key={option.id}
            {...option}
            language={language}
            checked={value && value.includes(option.id)}
            onChange={() => handleChange(option.id)}
          />
        ))}
        {options.length > SHOW_LESS && (
          <>
            {showMore ? (
              <div className="show_more" onClick={() => setShowMore(false)}>
                <span>
                  {language === "de" ? "Weniger anzeigen" : "Show less"}
                </span>
              </div>
            ) : (
              <div className="show_more" onClick={() => setShowMore(true)}>
                <span>{language === "de" ? "Mehr anzeigen" : "Show more"}</span>
              </div>
            )}
          </>
        )}
      </FormGroup>
    </FormControl>
  );
};

export default MultipleSelect;
