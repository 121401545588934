import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  ButtonGroup,
} from "@material-ui/core";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { ApiContext } from "../../../../Contexts/ApiContext";
import SingleSelect from "./SingleSelect";
import MultipleSelect from "./MultipleSelect";
import { Add, CloseRounded, Remove } from "@material-ui/icons";
import { translationsHelper } from "../../../../CustomHooks/useTranslations";

const OptionModal = ({ language = "de" }) => {
  const { filteredMeals, optionModal, setOptionModal, menuDispatch } =
    useContext(ApiContext);
  const [state, setstate] = useState({});

  useEffect(() => {
    setstate({ id: optionModal, amount: 1 });
  }, [optionModal]);

  const { optionsV02 = [], ...meal } = useMemo(() => {
    if (!filteredMeals || !filteredMeals.length) return {};

    let meal = filteredMeals.find((m) => m.id === optionModal) || {};

    return meal;
  }, [optionModal, filteredMeals]);

  const calculatedPrice = useMemo(() => {
    if (!meal) return 0;

    let price = meal.price || 0;

    let optionKeys = Object.keys(state).filter(
      (k) => k !== "id" && k !== "amount"
    );

    optionKeys.forEach((key) => {
      let option = optionsV02.find((o) => o.id === key);
      let val = state[key];

      if (!option) return;

      if (Array.isArray(val)) {
        val.forEach((v) => {
          let opt = option.options.find((o) => v === o.id) || {};
          price += opt.price || 0;
        });
      } else {
        let opt = option.options.find((o) => val === o.id) || {};
        price += opt.price || 0;
      }
    });

    return price * state.amount;
  }, [meal, state]);

  const handleChange = ({ target: { value, name } }) => {
    setstate((st) => ({ ...st, [name]: value }));
  };
  const handleSubmit = () => {
    let options = Object.keys(state)
      .filter((k) => k !== "id" && k !== "amount")
      .map((id) => ({ id, value: state[id] }));

    menuDispatch({
      type: "addToCart",
      payload: {
        id: state.id,
        options,
        amount: state.amount,
        price: calculatedPrice,
      },
    });
    setOptionModal(null);
  };

  console.log({ optionsV02 });

  return (
    <Dialog
      open={optionModal}
      maxWidth="sm"
      onClose={() => setOptionModal(null)}
      fullWidth
    >
      <DialogTitle onClose={() => setOptionModal(null)}>
        {translationsHelper(meal?.translations, language)?.title}
        <IconButton
          className="first-steps-close"
          size="small"
          onClick={() => setOptionModal(null)}
        >
          <CloseRounded color="inherit" size="small" />
        </IconButton>
      </DialogTitle>
      {optionModal && (
        <DialogContent>
          {optionsV02 &&
            optionsV02
              .sort((a, b) => (a.position ?? 1000) - (b.position ?? 1000))
              .map((option) => (
                <React.Fragment key={option.id}>
                  {option.multiple ? (
                    <MultipleSelect
                      name={option.id}
                      maxAmount={option?.maxAmount}
                      value={state[option.id] || []}
                      options={option.options.sort(
                        (a, b) => a.position - b.position
                      )}
                      language={language}
                      onChange={handleChange}
                      translations={option.translations}
                    />
                  ) : (
                    <SingleSelect
                      name={option.id}
                      value={state[option.id]}
                      options={option.options.sort(
                        (a, b) => a.position - b.position
                      )}
                      required={option.required}
                      language={language}
                      onChange={handleChange}
                      translations={option.translations}
                    />
                  )}
                </React.Fragment>
              ))}
        </DialogContent>
      )}
      <DialogActions className="option-modal-actions">
        <ButtonGroup>
          <Button
            disabled={state.amount <= 1}
            onClick={() => setstate((st) => ({ ...st, amount: st.amount - 1 }))}
          >
            <Remove />
          </Button>
          <Button>{state.amount || 0}</Button>
          <Button
            onClick={() => setstate((st) => ({ ...st, amount: st.amount + 1 }))}
          >
            <Add />
          </Button>
        </ButtonGroup>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          style={{ minWidth: 150 }}
        >
          {calculatedPrice.toFixed(2).replace(".", ",")} €
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptionModal;
