import { useMemo, useContext } from "react";
import { LanguageContext } from "../Contexts/LanguageContext";

export const translationsHelper = (translations = {}, language, defaultKey = 'title') => {
  let langs = Object.keys(translations || {}).filter(lang => typeof translations?.[lang] === "string" || defaultKey === 'self' ? !!translations?.[lang] : !!translations?.[lang]?.[defaultKey]);

  if (!langs.length) return {};

  if (langs.includes(language)) {
    return translations[language];
  } else {
    return translations[langs[0]];
  }
};

const useTranslations = (translations = {}, defaultKey) => {
  const { language } = useContext(LanguageContext);

  const translation = useMemo(
    () => translationsHelper(translations, language, defaultKey),
    [translations, language, defaultKey]
  );

  return translation;
};

export default useTranslations;
