import { useState, useCallback, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import "./highlightedSlider.css";
import Swipe from "react-easy-swipe";
import AddToCart from "../Meals/AddToCart";
import { translationsHelper } from "../../../CustomHooks/useTranslations";

const stringifyPrice = (price) => {
  if (!price) return null;

  let priceArr = price.toString().split(".");

  if (!priceArr[1]) {
    priceArr[1] = "00";
  } else if (parseInt(priceArr[1]) < 10) {
    priceArr[1] = priceArr[1] + "0";
  }

  return priceArr.join(".");
};

const background = (image) => {
  if (!image) return "lightgreen";
  return `linear-gradient(180deg, rgba(245, 245, 245, 0) 50%, #F5F5F5 100%), url('${image}')`;
};

const AnimatedTitle = ({ title = "", transitionProps = {} }) => {
  return (
    <span className="animated-title">
      <animated.p style={transitionProps}>{title}</animated.p>
    </span>
  );
};

const HighlightSlider = ({
  slides = [],
  language = "de",
  timeout = 5000,
  height = null,
}) => {
  const [index, set] = useState(0);

  const [to, setto] = useState(null);

  useEffect(() => {
    set(0);
  }, [slides]);

  const handleSwipeLeft = useCallback(
    () => set((state) => (state + 1) % slides.length),
    [slides, set]
  );

  const handleSwipeRight = useCallback(
    () =>
      set((state) => {
        let nV = (state - 1) % slides.length;
        if (nV < 0) {
          return slides.length - 1;
        } else {
          return nV;
        }
      }),
    [slides, set]
  );

  const transitions = useTransition(index, (p) => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  const titleTransitions = useTransition(index, (p) => p, {
    from: { transform: "translateY(26px)", opacity: 0 },
    enter: { transform: "translateY(0px)", opacity: 1 },
    leave: { transform: "translateY(26px)", opacity: 0 },
  });

  useEffect(() => {
    if (slides.length > 1) {
      if (to) clearTimeout(to);
      let t = setTimeout(handleSwipeLeft, timeout);
      setto(t);
    }

    return () => {
      clearTimeout(to);
    };
  }, [index, slides]);

  return (
    <Swipe onSwipeLeft={handleSwipeLeft} onSwipeRight={handleSwipeRight}>
      <div className="highlighted-slider" style={{ height }}>
        {transitions.map(({ item, props, key }) => {
          const slide = slides[item];

          const tags = slide.tags[language];

          return (
            <animated.div
              className={`highlighted-slider-card highlight-${slide.id}`}
              key={key}
              style={{
                opacity: props,
              }}
            >
              {!!tags && (
                <animated.span style={props} className="highlighted-tags">
                  {tags}
                </animated.span>
              )}
            </animated.div>
          );
        })}

        {titleTransitions.map(({ item, props, key }) => {
          const slide = slides[item];
          const { title = "Test" } = translationsHelper(
            slide?.meal?.translations,
            language
          );

          return (
            <div className="animated-title-container">
              <AnimatedTitle title={title} transitionProps={props} />
              <AnimatedTitle
                title={stringifyPrice(slide.price)}
                transitionProps={props}
              />
            </div>
          );
        })}

        <div className="highlight-dot-container">
          {slides.map((s, i) => (
            <span className={`highlight-dot ${index === i ? "active" : ""}`} />
          ))}
        </div>
        <AddToCart
          id={slides[index].mealId}
          style={{ position: "absolute", right: 10, bottom: 10 }}
        />
      </div>
    </Swipe>
  );
};

export default HighlightSlider;
