import { useContext, useEffect, useMemo } from "react";
import { ApiContext } from "../../../Contexts/ApiContext";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { Typography } from "@material-ui/core";
import MealItem from "../Meals/MealItem";
import CategoryImage from "../Meals/CategoryImage";
import HighlightSlider from "../Categories/HighlightSlider";
import SubCategory from "./SubCategory";
import useTranslations, {
  translationsHelper,
} from "../../../CustomHooks/useTranslations";

const matchPreferences = (
  { vegan, alcoholic, vegetarian },
  preferences = []
) => {
  if (!preferences.length) return true;

  if (preferences.includes("noAlc") && alcoholic) return false;

  if (preferences.includes("vegan") && !vegan) return false;

  if (preferences.includes("veggie") && !vegetarian) return false;

  return true;
};

const sortByTag = (a, b) => {
  let aTag = a.translations.de.tags ? a.translations.de.tags : "";
  let bTag = b.translations.de.tags ? b.translations.de.tags : "";

  if (aTag === bTag) {
    return 0;
  }

  aTag = aTag.split("").map((a) => a.charCodeAt(0));
  bTag = bTag.split("").map((a) => a.charCodeAt(0));

  if (aTag.length >= bTag.length) {
    return (
      parseInt(bTag.slice(0, bTag.length - 1).join("")) -
      parseInt(aTag.slice(0, bTag.length - 1).join(""))
    );
  } else {
    return (
      parseInt(bTag.slice(0, aTag.length - 1).join("")) -
      parseInt(aTag.slice(0, aTag.length - 1).join(""))
    );
  }
};

const sortByPrice = (a, b) => {
  if (!a || !b) {
    return 0;
  }

  if (a.price && b.price) return a.price - b.price;

  let aPrice = a.price
    ? a.price
    : a.options?.sort((a, b) => a.price - b.price)[0]?.price || 0;
  let bPrice = b.price
    ? b.price
    : b.options?.sort((a, b) => a.price - b.price)[0]?.price || 0;

  return aPrice - bPrice;
};

const Category = ({ data: category }) => {
  const { state, menuDispatch, menu, filteredMeals: meals } = useContext(
    ApiContext
  );
  const { language } = useContext(LanguageContext);

  const filteredSubCategories = useMemo(() => {
    if (!state.subCategories) return [];

    return state.subCategories
      .filter((sC) => sC.category === category.id)
      .sort((a, b) => {
        if (a.position || a.position == 0) {
          return a.position - b.position;
        } else {
          return a.shortName.localeCompare(b.shortName);
        }
      });
  }, [meals, category.id, state.subCategories]);

  const filteredMeals = useMemo(() => {
    if (!meals) return [];
    // @TODO && m.showOnMenu

    return meals
      .filter(
        (m) =>
          m.category == category.id &&
          (!m.subCategory ||
            !filteredSubCategories.find((sC) => m.subCategory === sC.id))
      )
      .sort(sortByPrice)
      .sort(sortByTag);
  }, [meals, category.id, filteredSubCategories]);

  const highlightedMeals = useMemo(
    () =>
      state.highlights?.filter(
        (h) =>
          h.categoryId === category.id &&
          matchPreferences(h.meal, menu.preferences)
      ) ?? [],
    [state.highlights, menu.preferences, category.id]
  );

  const { title = "", description = "" } = useTranslations(
    category.translations
  );

  return (
    <div
      style={{ paddingBottom: filteredMeals.length ? 70 : 0 }}
      id={"cat-" + category.id}
      key={category.id}
    >
      {category && !!meals.filter((m) => m.category == category.id).length && (
        <CategoryImage
          img={category.image}
          title={title}
          description={description}
          styleOptions={state.restaurant}
        />
      )}
      {false && (
        <div className="categoryHeader">
          <Typography variant="h2" style={{ marginBottom: 10 }}>
            {title}
          </Typography>
        </div>
      )}
      {!!highlightedMeals.length && (
        <HighlightSlider slides={highlightedMeals} />
      )}
      {filteredSubCategories.map((subCategory) => (
        <SubCategory language={language} subCategory={subCategory} />
      ))}
      {filteredMeals.map((meal) => (
        <MealItem language={language} meal={meal} />
      ))}
    </div>
  );
};

export default Category;
