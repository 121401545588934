import { Typography } from '@material-ui/core';
const SubCategoryImage = ({ id, title = '', img = '' }) => {

  if(!img) {
    return <></>
  }
  return (
    <div
      className={`subCategoryItem ${!img ? "noImage" : null}`}
      key={id}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("${img}")`
      }}
    >
      <Typography style={{ filter: 'drop-shadow(2px 4px 6px black)' }}>{title}</Typography>
    </div>
  );
};
export default SubCategoryImage;