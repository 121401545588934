import { useState, useContext } from "react";
import { InputBase } from "@material-ui/core";
import { ArrowForwardRounded, EuroRounded } from "@material-ui/icons";
import { ApiContext } from "../../../Contexts/ApiContext";

const Tip = ({ language = "de" }) => {
  const [message, setmessage] = useState("");
  const [individualAmount, setindividualAmount] = useState("");
  const {
    menuDispatch,
    menu: { tip = {}, date, orderTime },
    restaurantId,
  } = useContext(ApiContext);

  const handleClick = (amount) => () => {
    menuDispatch({ type: "tipAdded", payload: { amount, type: "percentage" } });

    if (neoSpecialDay) {
      setmessage(
        language === "de"
          ? "Danke für deine Spende ❤️"
          : "Thank you for your Donation! ❤️"
      );
      return;
    }

    setmessage(
      language === "de"
        ? "Danke für dein Trinkgeld ❤️"
        : "Thank you for your Tip! ❤️"
    );
  };

  const neoSpecialDay =
    restaurantId === "neo-heidelberg" &&
    (orderTime.includes("2021-04-13") ||
      new Date().toISOString().startsWith("2021-04-13"));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      typeof parseFloat(individualAmount) !== "number" &&
      !isNaN(parseFloat(individualAmount))
    ) {
      setmessage(
        language === "de" ? "Es ist ein Fehler aufgetreten" : "Not a number"
      );

      return;
    }

    menuDispatch({
      type: "tipAdded",
      payload: { amount: parseFloat(individualAmount), type: "individual" },
    });

    setmessage(
      language === "de"
        ? "Danke für dein Trinkgeld ❤️"
        : "Thank you for your Tip! ❤️"
    );
  };

  const handleChange = ({ target: { value } }) => setindividualAmount(value);

  return (
    <div className="tip-container">
      <h5>
        {neoSpecialDay
          ? "Zusätzlich Spende an das 𝗪𝗮𝗹𝗱𝗽𝗶𝗿𝗮𝘁𝗲𝗻-𝗖𝗮𝗺𝗽 𝗱𝗲𝗿 𝗱𝗲𝘂𝘁𝘀𝗰𝗵𝗲𝗻 𝗞𝗶𝗻𝗱𝗲𝗿𝗸𝗿𝗲𝗯𝘀𝘀𝘁𝗶𝗳𝘁𝘂𝗻𝗴 𝗲.𝗩"
          : "Tip your Driver"}
      </h5>
      <div>
        <button
          onClick={handleClick(neoSpecialDay ? 25 : 5)}
          style={{
            background:
              tip &&
              (tip.amount === 5 || tip.amount === 25) &&
              tip.type === "percentage"
                ? "rgba(255,255,255,0.7)"
                : null,
          }}
        >
          <span>{neoSpecialDay ? 25 : 5}%</span>
          <span>🙂</span>
        </button>

        <button
          onClick={handleClick(neoSpecialDay ? 50 : 10)}
          style={{
            background:
              tip &&
              (tip.amount === 10 || tip.amount === 50) &&
              tip.type === "percentage"
                ? "rgba(255,255,255,0.7)"
                : null,
          }}
        >
          <span>{neoSpecialDay ? 50 : 10}%</span>
          <span>😊</span>
        </button>

        <button
          onClick={handleClick(neoSpecialDay ? 100 : 15)}
          style={{
            background:
              tip &&
              (tip.amount === 15 || tip.amount === 100) &&
              tip.type === "percentage"
                ? "rgba(255,255,255,0.7)"
                : null,
          }}
        >
          <span>{neoSpecialDay ? 100 : 15}%</span>
          <span>❤️</span>
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <InputBase
            value={individualAmount}
            onChange={handleChange}
            placeholder="Individueller Betrag"
          />
          <EuroRounded style={{ height: 16 }} />
        </div>
        <button type="submit">
          <ArrowForwardRounded />
        </button>
      </form>
      <div>{message}</div>
    </div>
  );
};

export default Tip;
